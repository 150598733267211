/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components material ui
// styles
import {
  Grid, TextField, Typography,
} from '@material-ui/core';
import useStyles from './styles';

function Evidence({
  handleEvidenceComment,
  handleEvidenceUrl,
  answerId,
  answerUrl,
  isURLRequired,
  answerComment,
  isReviewSection,
  slug,
  disabled,
  hasErrors,
}) {
  const classes = useStyles();
  const [comment, setComment] = useState(answerComment);
  const [sourceUrl, setSourceUrl] = useState(answerUrl);

  return isReviewSection ? (
    <Grid container spacing={3} className={classes.evidenceContainerReview}>
      <Grid item xs={11} xl={8}>
        <Typography variant="body2">
          Answer
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {answerId}
        </Typography>
      </Grid>
      <Grid item xs={11} xl={8}>
        <Typography variant="body2">
          Source URL:
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {sourceUrl}
        </Typography>
      </Grid>
      {comment && (
      <Grid item xs={11} xl={8}>
        <Typography variant="body2">
          Comment:
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {comment}
        </Typography>
      </Grid>
      )}
    </Grid>
  ) : (
    <Grid container spacing={3} className={classes.evidenceContainer}>
      <Grid item xs={12} container alignItems="center">
        <Typography variant="body2" className={classes.title}>
          Evidence
        </Typography>
        {isURLRequired ? <Typography variant="caption">(Url Required)</Typography> : ''}
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="source"
          label="Source URL"
          variant="outlined"
          required={isURLRequired}
          error={hasErrors(answerId)}
          fullWidth
          value={sourceUrl}
          autoFocus
          disabled={disabled}
          onChange={(e) => {
            setSourceUrl(e.target.value);
            handleEvidenceUrl(e, answerId, comment, slug);
          }}
          style={{ backgroundColor: '#f5f5f5' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="evidence"
          multiline
          variant="outlined"
          value={comment}
          label="Analyst Comment"
          fullWidth
          disabled={disabled}
          onChange={(e) => {
            setComment(e.target.value);
            handleEvidenceComment(e, answerId, sourceUrl, slug);
          }}
          style={{ backgroundColor: '#f5f5f5' }}
        />
      </Grid>
    </Grid>
  );
}

Evidence.propTypes = {
//   value: PropTypes.number.isRequired,
//   changeValue: PropTypes.func.isRequired,
//   setSize: PropTypes.func.isRequired,
//   size: PropTypes.number.isRequired,
//   questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleEvidenceComment: PropTypes.func.isRequired,
  handleEvidenceUrl: PropTypes.func.isRequired,
  answerId: PropTypes.number.isRequired,
  answerUrl: PropTypes.string.isRequired,
  answerComment: PropTypes.string.isRequired,
  isReviewSection: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  hasErrors: PropTypes.func.isRequired,
  isURLRequired: PropTypes.bool.isRequired,
};
export default Evidence;
