/* eslint-disable react/prop-types */
import React from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';

//  styles
import './global.css';
// components
import Layout from './Layout/Layout';

// pages
import Error from '../pages/error/Error';
import Login from '../pages/auth/login/Login';
// context
import { useUserState } from '../context/UserContext';

export default function App() {
  // global
  const { isAuthenticated } = useUserState();

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/risk-tool" />} />

        <PrivateRoute path="/scraper-tool/brand/:id" component={Layout} />
        <PrivateRoute path="/risk-tool/brand/:id" component={Layout} />
        <PrivateRoute exact path="/scraper-tool" component={Layout} />
        <PrivateRoute exact path="/risk-tool" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgot-password" component={Layout} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => ((isAuthenticated) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        ))}
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => ((isAuthenticated) ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  }
}
