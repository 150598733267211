import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    paddingLeft: '5rem',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.main}}`,
  },
  buttonPrimary: {
    backgroundColor: 'black',
    color: 'snow',
  },
  answerContainer: {
    borderRadius: '5px',
    marginBottom: '1.5rem',
    backgroundColor: '#d4e1de6e',
    padding: '12px 12px',
  },
  answerContainerOutdated: {
    borderRadius: '5px',
    marginBottom: '1.5rem',
    backgroundColor: '#eab34921',
    padding: '12px 12px',
  },
  answerText: {
    fontWeight: '400',
    inlineSize: 'fit-content',
  },
  details: {
    display: 'inline-block',
  },
  accordionSummary: {
    padding: 0,
    backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)'
          : '#f8fafc',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  },

}));
