import React from 'react';
import PropTypes from 'prop-types';

// Material UI components
import { Typography } from '@material-ui/core';

// styles
import useStyles from './styles';

const LinkExternal = ({ href, text }) => {
  const classes = useStyles();
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={classes.link}
    >
      <Typography variant="caption" className={classes.text}>
        {text}
      </Typography>
    </a>
  );
};
LinkExternal.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
export default LinkExternal;
