import Api from './Api';

export default class JobsAPIs extends Api {
  getAllJobs(accessToken) {
    return this.instance.get('jobs', this.constructor.addAuthorizationHeader(accessToken));
  }

  filterByType(accessToken, scraperType) {
    return this.instance.post('jobs', { scraperType }, this.constructor.addAuthorizationHeader(accessToken));
  }

  startJob(accessToken, data) {
    return this.instance.post('jobs', data, this.constructor.addAuthorizationHeader(accessToken));
  }

  getSpecificJob(accessToken, id) {
    return this.instance.get(`jobs/${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  deleteJob(accessToken, id) {
    return this.instance.delete(`jobs/${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  patchJobExpertDecision(accessToken, id, expertDecision) {
    return this.instance.patch(`jobs/${id}`, { expertDecision }, this.constructor.addAuthorizationHeader(accessToken));
  }
}
