import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  'chip-info': {
    backgroundColor: theme.palette.success.light,
    borderRadius: 30,
    margin: '0 4px',
    '& > span': {
      color: theme.palette.success.dark,
    },
  },
  'chip-no-info': {
    backgroundColor: theme.palette.error.light,
    borderRadius: 30,
    margin: '0 4px',
    '& > span': {
      color: theme.palette.error.dark,
    },
  },
  'chip-probably-info': {
    backgroundColor: theme.palette.warning.light,
    borderRadius: 30,
    margin: '0 4px',
    '& > span': {
      color: theme.palette.warning.dark,
    },
  },
  chipnone: {
    backgroundColor: 'transparent',
    borderRadius: 30,
    margin: '0 4px',
  },
}));
