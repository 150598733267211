import * as yup from 'yup';

// Local components
// import TextInput from '../../components/common/textInput/TextInput';
// Material Ui
import {
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import TableJobStatusRefactor from './tables/TableJobsStatusRefactor';
import { signOut, useUserDispatch, useUserState } from '../../context/UserContext';
import { BrandsAPIsSingleton as APIBrands, JobsAPIsSingleton as APIJobs } from '../../modules/api';
// styles
import useStyles from './styles';

// error boundaries
// eslint-disable-next-line no-useless-escape
const urlFormatExpression = new RegExp('([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})');
const schema = yup.object().shape({
  brandValue: yup.string().required('Please include a brand name'),
  urlValue: yup.lazy(
    (value) => {
      if (!value) {
        return yup.string().required('Please include a website URL');
      }
      return yup.string().matches(urlFormatExpression, { message: 'Please include a valid URL, eg brandname.com' }).required('Please include a website URL');
    },
  ),
// eslint-disable-next-line react/prop-types
}); export default function Scraper({ history }) {
  const classes = useStyles();
  const { accessToken } = useUserState();
  const userDispatch = useUserDispatch();

  // local
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please include a valid email or password');
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobsData, setJobsData] = useState([]);
  const [scraperType, setScraperType] = useState('');
  const [brandData, setBrandData] = useState([]);
  const [brandValue, setBrandValue] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [resetAutoComplete, setResetAutoComplete] = useState('test');

  //  handles

  const handleOnChangeBranName = React.useCallback((value) => {
    setError(false);
    if (value) {
      setBrandValue(value.name);
      setUrlValue(value.url);
    }
  }, []);

  const handleStartScraper = async () => {
    await schema
      .validate({
        brandValue,
        urlValue,
      })
      .then(() => {
        const external = brandData
          .find((brand) => brand.name.toLowerCase() === brandValue.toLowerCase());
        setIsLoading(true);
        const data = {
          brand: brandValue,
          url: urlValue,
          external: !external === undefined,
        };
        APIJobs.startJob(accessToken, scraperType ? { ...data, scraperType } : data)
          .then(() => {
            setIsLoading(false);
            setBrandValue('');
            setUrlValue('');
            handleOnChangeBranName({
              name: '',
              url: '',
            });
            setResetAutoComplete(Math.random());
            setUpdate(!update);
          })
          .catch((e) => {
            setIsLoading(false);
            if (e && e.message && e.message === 'LOGOUT') {
              // eslint-disable-next-line no-console
              console.log(e);
            }
          });
      }).catch((e) => {
        setBrandValue('');
        setUrlValue('');
        setError(true);
        setErrorMessage(e.message);
      });
  };

  const handleRemoveJob = (id) => {
    APIJobs.deleteJob(accessToken, id)
      .then(() => {
        setUpdate(!update);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e && e.message && e.message === 'LOGOUT') {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
  };

  useEffect(() => {
    APIJobs.getAllJobs(accessToken)
      .then((r) => {
        const { data } = r;
        setJobsData(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });

    APIBrands.getAllBrands(accessToken)
      .then((r) => {
        const { data } = r;
        setBrandData(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
  }, [update]);

  const handleUpdateData = () => () => {
    setUpdate(!update);
  };

  const jobs = React.useMemo(() => jobsData,
    [jobsData]);
  const isAlreadyScraped = jobs.filter((job) => job.brand === brandValue);

  const [jobAlreadyScraped, dateScraped] = [
    isAlreadyScraped[isAlreadyScraped.length - 1]?.brand,
    isAlreadyScraped[isAlreadyScraped.length - 1]?.createdAt,
  ];

  return (
    <Grid container className={classes.container}>
      <Grid item container alignItems="flex-end" className={classes.scraperForm}>
        <Grid item style={{ minWidth: '400px', marginRight: '8px' }}>
          <Autocomplete
            id="autocomplete"
            options={brandData}
            key={resetAutoComplete}
            freeSolo
            getOptionLabel={(option) => option.name || ''}
            style={{ width: '100%' }}
            value={brandValue.value || ''}
            onChange={(event, newValue) => {
              handleOnChangeBranName(newValue);
            }}
            renderInput={(params) => (
              <TextField
                ref={params.InputProps.ref}
                {...params}
                onChange={(e) => setBrandValue(e.target.value)}
                label="Brand Name"
                variant="outlined"
                error={error}
              />
            )}
          />
        </Grid>
        <Grid item style={{ minWidth: '400px', marginRight: '8px' }}>
          <TextField
            variant="outlined"
            value={urlValue || ''}
            label="Website Url"
            placeholder="Website Url"
            fullWidth
            onChange={(e) => setUrlValue(e.target.value)}
            error={error}
          />
        </Grid>
        <Grid item style={{ minWidth: '200px', marginRight: '8px' }}>
          <FormControl variant="outlined" style={{ minWidth: '150px' }}>
            <InputLabel id="scraperType-label">Scraper Type</InputLabel>
            <Select
              id="scraperType"
              labelId="scraperType-label"
              label="Scraper Type"
              value={scraperType || ''}
              placeholder="Select scraper type"
              onChange={(e) => setScraperType(e.target.value)}
              error={error}
            >
              <MenuItem value="">
                {scraperType ? 'None' : 'Select scraper type'}
                {' '}
              </MenuItem>
              <MenuItem value="fashion">Fashion</MenuItem>
              <MenuItem value="beauty">Beauty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => handleStartScraper()}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress color="secondary" size="24px" /> : 'Start'}
          </Button>
        </Grid>
        <Fade in={error}>
          <Typography color="primary" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </Fade>
        <Fade in={(jobAlreadyScraped)}>
          <Grid item xs={12} align="center" className={classes.warningMessageContainer}>
            { brandValue && (
            <Typography color="primary" className={classes.warningMessage}>
              {jobAlreadyScraped}
              {' '}
              has already been processed on the
              {' '}
              {moment(dateScraped).format('DD MMM \'YY')}
            </Typography>
            ) }
          </Grid>
        </Fade>
      </Grid>

      <Grid justifyContent="space-between" item container alignItems="flex-end">
        <Grid container item xs={12} style={{ minHeight: '66vh' }}>
          <TableJobStatusRefactor
            data={jobs}
            update={handleUpdateData}
            removeThisJob={(handleRemoveJob)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
