/* eslint-disable react/prop-types */
import React from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { Grid } from '@material-ui/core';

// icons

// styles
import useStyles from './styles';

// components
// import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
// pages
import Scraper from '../../pages/scraper/Scraper';
import Brand from '../../pages/brand/Brand';
import BrandRisk from '../../pages/brandRisk/BrandRisk';
import RiskTool from '../../pages/risktool/RiskTool';
// context
// import { useLayoutState } from '../../context/LayoutContext';
import Login from '../../pages/auth/login/Login';
// import ForgotPassword from '../../views/login/ForgotPassword';

function Layout({ history }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item container className={classes.containerHeadbar}>
        {/* <Header /> */}
      </Grid>
      <Sidebar history={history} />
      <Grid item container>
        <Grid item container className={classes.col}>
          <Grid item container xs={12} className={classes.containerPage}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route exact path="/scraper-tool" component={Scraper} />
              <Route exact path="/risk-tool" component={RiskTool} />
              <Route path="/scraper-tool/brand/:id" component={Brand} />
              <Route path="/risk-tool/brand/:id" component={BrandRisk} />
              {/*
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/home" component={Home} />
                 */}
            </Switch>
          </Grid>
        </Grid>

      </Grid>
    </Grid>

  );
}

export default withRouter(Layout);
