/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
// components material ui
import {
  FormControl,
  Grid, InputLabel,
  Input, TextField, Typography, FormHelperText, Button,
  IconButton, CircularProgress,
} from '@material-ui/core';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// styles
import useStyles from './styles';
import LinkExternal from '../../../../components/common/link/LinkExternal';

function SubHeader({
  brand, brandURL, size,
  handleNewUrl, loading, handleBrandStatus, handleAnswers, openForm, setOpenForm,
  showVerificationCompletedButton,
}) {
  const classes = useStyles();
  const [newUrl, setNewUrl] = useState('');

  const { riskStatus, name, scraperStatus } = brand;
  const riskStatus2 = riskStatus ? riskStatus[0] : { status: 'Not Started' };
  const disableButtonSubmitVerification = riskStatus2.status !== 'Answered';
  const disableButtonSkip = riskStatus2.status !== 'In verification';
  const disableButtonVerify = riskStatus2.status === 'Not started' || riskStatus2.status === 'In progress' || riskStatus2.status === 'Answered' || riskStatus2.status === 'Verified';
  const status = {
    inProgress: riskStatus.find((item) => item.status === 'In progress'),
    answered: riskStatus.find((item) => item.status === 'Answered'),
    submittedForVerification: riskStatus.find((item) => item.status === 'In verification'),
    verificationSkipped: riskStatus.find((item) => item.status === 'Verification skipped'),
    verified: riskStatus.find((item) => item.status === 'Verified'),
  };
  return (
    <Grid
      container
      justifyContent="revert"
      alignItems="flex-end"
    >
      <Grid container direction="column" item wrap="nowrap" xs={3} md={3}>
        <Typography variant="h4">
          {`${name} - ${_.capitalize(size) || ''}`}
        </Typography>
        <Grid
          className={classes.containerLink}
          container
          item
          justifyContent="space-between"
          alignItems="center"
          xs={9}
          lg={10}
        >
          <Grid item>
            <LinkExternal href={brandURL} text={brandURL} />
            <IconButton
              size="small"
              onClick={() => setOpenForm(!openForm)}
              edge="end"
            >
              {openForm
                ? <CloseRoundedIcon className={classes.editIcon} />
                : <EditRoundedIcon className={classes.editIcon} /> }
            </IconButton>
          </Grid>
        </Grid>
        {openForm && (
          <Grid container justifyContent="space-between" className={classes.formContainer}>
            <Grid item xs={7}>
              <TextField
                label="Change Url"
                id="my-input"
                aria-describedby="my-helper-text"
                onChange={(e) => setNewUrl(e.target.value)}
                autofocus
                focused
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                disabled={!newUrl}
                variant={loading ? 'outlined' : 'contained'}
                color="primary"
                onClick={() => handleNewUrl(newUrl, setOpenForm)}
              >
                {loading ? <CircularProgress size={16} color="secondary" /> : 'Update' }
              </Button>
            </Grid>
          </Grid>
        )}

      </Grid>
      <Grid item container justifyContent="space-between" xs={9} md={9} style={{ paddingLeft: '1rem', alignSelf: 'end' }}>
        {/* info container */}
        <Grid container item className={classes.infoContainer} wrap="wrap-reverse" md={9} xl={9}>
          <Grid item container justifyContent="center">
            <Grid container item xs={12} sm={6} alignItems="center">
              <Grid item xs={12}>
                {status.answered && (
                <Typography component="span" style={{ fontWeight: 500 }}>
                  Answers Completed:
                  {' '}
                  <Typography variant="body1" component="span">
                    {status.answered && status.answered.user.username}
                    {' '}
                    -
                    {' '}
                    {status.answered && moment(status.answered.date).format('DD MMM YYYY')}
                  </Typography>
                </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} alignItems="center" style={{ paddingLeft: '1rem' }}>
              <Grid item xs={12}>
                <Typography component="span" style={{ fontWeight: 500 }}>
                  Reviewed:
                  <Typography variant="body1" component="span">

                    {' '}
                    {status.verified && status.verified.user.username}
                    {' '}
                    -
                    {' '}
                    {status.verified && moment(status.verified.date).format('DD MMM YYYY')}
                    {' '}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid container item xs={12} sm={6} alignItems="center" justifyContent="space-between">
              <Grid item xs={12}>
                <Typography component="span" style={{ fontWeight: 500 }}>
                  Status:
                  {' '}
                  <Typography variant="body1" component="span" style={{ borderBottom: '1px solid black' }}>
                    {riskStatus2.status}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} alignItems="center" style={{ paddingLeft: '1rem' }}>
              <Grid item xs={12}>
                <Typography component="span" style={{ fontWeight: 500 }}>
                  Scraped:
                  {' '}
                  <Typography variant="body1" component="span">
                    {scraperStatus}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* buttons */}
        <Grid item container justifyContent="flex-end" alignItems="center" md={3} xl={2} style={{ paddingLeft: '1rem' }}>
          <Grid item xs={12} style={{ padding: '.3rem' }}>
            <Button
              disabled={disableButtonSubmitVerification}
              size="small"
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => handleBrandStatus('In verification')}
            >
              Submit for verification
            </Button>
          </Grid>
          <Grid item xs={12} style={{ padding: '.3rem' }}>
            <Button
              disabled={disableButtonSkip}
              size="small"
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => handleBrandStatus('Verification skipped')}
            >
              Skip Verification
            </Button>
          </Grid>
          <Grid item xs={12} style={{ padding: '.3rem' }}>
            <Button
              fullWidth
              disabled={disableButtonVerify || !showVerificationCompletedButton}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleAnswers('copy', true)}
            >
              Verification Complete
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SubHeader.propTypes = {
  history: PropTypes.shape({}).isRequired,
  brand: PropTypes.shape({
    name: PropTypes.string,
    riskStatus: PropTypes.arrayOf(PropTypes.shape({})),
    scraperStatus: PropTypes.string,
  }).isRequired,
  brandURL: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  handleNewUrl: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleBrandStatus: PropTypes.func.isRequired,
  handleAnswers: PropTypes.func.isRequired,
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  showVerificationCompletedButton: PropTypes.bool.isRequired,
};
export default SubHeader;
