import { makeStyles } from '@material-ui/styles';

const drawerWidth = 240;

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    backgroundColor: 'white',
    padding: '.5rem 2rem',
  },
  logo: {
    padding: '0 .5rem',
    paddingRight: '1rem',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
    },
  },
  avatarContainer: {
    margin: '30px 0',
  },
  avatar: {
    backgroundColor: theme.palette.grey[600],
  },
  pageLink: {
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.primary.main,
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    minWidth: 100,
    '&:last-child': {
      paddingRight: '1rem',
    },
    '&:hover': {
      color: theme.palette.grey[700],
    },
  },

  linkActive: {
    borderBottom: `2px solid ${theme.palette.grey[900]}`,
  },
  iconLast: {
    paddingBottom: '2rem',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    transition: 'all 0.2s ease',
    '&:hover': {
      color: theme.palette.grey[700],
    },
  },
}));
