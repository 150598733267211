import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  infoContainer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 10,
    padding: '1rem',
    boxShadow: theme.customShadows.xs,
  },
  text: {
    padding: '0 1rem',
  },
  formContainer: {
    padding: '1rem',
    marginTop: '1rem',
    boxShadow: theme.customShadows.xs,
    alignItems: 'flex-end',
  },
  containerLink: {
    paddingTop: '1rem',
  },
  editIcon: {
    fontSize: '1rem !important',
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
