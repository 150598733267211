/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// components material ui
import {
  Grid, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, Button, TextField,
} from '@material-ui/core';
import {
  Person as AccountIcon,
} from '@material-ui/icons';
// icons
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// local components
import { useUserState } from '../../context/UserContext';
import { CommentsAPIsSingleton as APIComments } from '../../modules/api';
// styles
import useStyles from './styles';

export default function Comment({ comment, updateJob, updateComments }) {
  const classes = useStyles();
  const { accessToken, user } = useUserState();
  // eslint-disable-next-line no-underscore-dangle
  const userId = user._id;

  // local state
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [typeEdit, setTypeEdit] = useState(comment.type);
  const [textEdit, setTextEdit] = useState(comment.text);
  const [isLoading, setIsLoading] = useState(false);

  // handles
  const handleChange = (event) => {
    setTypeEdit(event.target.value);
  };
  const handleDeleteComment = () => {
    setIsLoading(true);
    APIComments.deleteComment(accessToken, comment._id)
      .then(() => {
        setIsConfirm(false);
        updateComments(
          (prevState) => prevState.filter((prevComment) => prevComment._id !== comment._id),
        );
        return updateJob();
      })
      .catch((e) => console.log(e));
  };

  const handlePatchComment = () => () => {
    if (!typeEdit || !textEdit) return;
    const data = {
      type: typeEdit,
      text: textEdit,
    };
    setIsLoading(true);
    APIComments.patchComment(accessToken, comment._id, data)
      .then((r) => {
        setIsLoading(false);
        updateJob();
        updateComments(
          (prevState) => {
            prevState.filter((prevComment) => prevComment._id !== comment._id);
            prevState.push(r.data[0]);
          },
        );
      })
      .catch((e) => {
        setIsLoading(false);
        if (e && e.message && e.message === 'LOGOUT') {
          console.log(e);
        }
      });
    setIsEdit(false);
  };

  return (
    <>

      {!isEdit ? (
        <Grid
          container
          justifyContent="center"
          direction="column"
          className={classes.containerComment}
          key={comment._id}
        >
          {isConfirm
      && (
      <Grid container item className={classes.overlayComment}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">Are you sure ?</Typography>
        </Grid>
        <Grid item container justifyContent="space-around" xs={12}>
          <Grid item xs={5}>
            <Button
              size="small"
              className={classes.buttonConfirmDelete}
              variant="outlined"
              onClick={() => handleDeleteComment()}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              size="small"
              onClick={() => setIsConfirm(false)}
              className={classes.buttonCancelDelete}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      )}

          <Grid item container className={classes.containerAuthor} justifyContent="space-between">
            <Grid item container xs={7}>
              <AccountIcon />
              <Typography variant="h6">
                {' '}
                {comment.user.username}
              </Typography>
            </Grid>
            {userId === comment.user.id && (
            <Grid item container xs={4} justifyContent="flex-end">
              <IconButton
                size="small"
                onClick={() => setIsConfirm(true)}
              >
                <DeleteOutlineOutlinedIcon className={classes.deleteBTN} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => setIsEdit(true)}
              >
                <EditOutlinedIcon className={classes.editBTN} />
              </IconButton>

            </Grid>
            )}
          </Grid>

          {comment.date && (
          <Grid item container>
            <Typography variant="caption" className={classes.typeTextComment}>
              {comment.type}
            </Typography>
          </Grid>
          )}
          <Grid item container className={classes.commentTextBox}>
            <Typography variant="body1" className={classes.commentText}>
              {comment.text}
            </Typography>
          </Grid>
          {comment.date && (
          <Grid item container>
            <Typography variant="caption" className={classes.date}>
              {moment(comment.date).format('Do-MM-YY, h:mm:ss a')}
            </Typography>
          </Grid>
          )}
        </Grid>
      ) : (
        <Grid container>
          <Grid
            container
            justifyContent="center"
            direction="column"
            className={classes.containerComment}
            key={comment._id}
          >
            {isConfirm
      && (
      <Grid container className={classes.overlayComment}>
        <Grid item xs={12}>
          <Typography align="center" variant="h6">Are you sure ?</Typography>
        </Grid>
        <Grid item container justifyContent="space-around" xs={12}>
          <Grid item xs={5}>
            <Button
              className={classes.buttonConfirmDelete}
              variant="outlined"
              onClick={() => handleDeleteComment()}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              onClick={() => setIsConfirm(false)}
              className={classes.buttonCancelDelete}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      )}
            <Grid item container className={classes.containerAuthor} justifyContent="space-between">

              <Grid item container xs={6}>
                <AccountIcon />
                <Typography variant="h5">
                  {' '}
                  {comment.user.username}
                </Typography>
              </Grid>
              <Grid item container xs={4} justifyContent="flex-end">
                <IconButton
                  size="small"
                  onClick={() => setIsConfirm(true)}
                >
                  <DeleteOutlineOutlinedIcon className={classes.deleteBTN} />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => setIsEdit(true)}
                >
                  <EditOutlinedIcon className={classes.editBTN} />
                </IconButton>
              </Grid>
            </Grid>
            <FormControl variant="outlined" className={classes.formControlComment}>
              <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={typeEdit}
                onChange={handleChange}
                label="Type"
                fullwidth="true"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="not-info">Term not info in this context</MenuItem>
                <MenuItem value="not-reported">Scraper didn&apos;t report a relevant term</MenuItem>
                <MenuItem value="new-term">New term - Synonym</MenuItem>
                <MenuItem value="unrelated-context">Term relates to a different entity</MenuItem>
              </Select>
              <TextField
                id="type"
                value={textEdit}
                onChange={(e) => setTextEdit(e.target.value)}
                margin="normal"
                type="text"
                fullwidth="true"
                variant="outlined"
                label="Comment"
              />
            </FormControl>
            <Grid item container justifyContent="space-between" className={classes.buttonsComment}>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.buttonConfirm}
                  onClick={handlePatchComment()}
                  disabled={isLoading}
                >
                  Comment
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.buttonCancel}
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

Comment.propTypes = {
  comment: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.shape({ username: PropTypes.string, id: PropTypes.string }).isRequired,
    _id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
  updateJob: PropTypes.func.isRequired,
  updateComments: PropTypes.func.isRequired,
};
