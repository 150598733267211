/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

// components material ui
import {
  Tabs, Tab, Typography, Box, Button, Grid,
} from '@material-ui/core';

// components local
import TabContentSize from '../main/TabContentSize';
import TabContentQuestions from '../main/TabContentQuestions';
import TabContentReview from '../main/TabContentReview';
import TabContentIndicators from '../main/TabContentIndicators';

// styles
import useStyles from './styles';

function TabPanel(props) {
  const {
    // eslint-disable-next-line react/prop-types
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      scrollButtons="auto"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabsHeader({
  value, changeValue, setSize, url, questions, setAnswers, answers,
  beAnswers, isQuestionAnswered, isCorrectURL, setIsCorrectURL,
  setUpdated, updated, brandId, brandName, size, handleBrandStatus,
  criteria, riskStatus, answersWithError,
  handleVerificationQuestions, verificatedQuestions, dependencies,
  openForm, handleOutDatedAnswer,
  setOpenForm, handleAnswers, showSaveButton, expanded, setExpanded,
}) {
  const classes = useStyles();

  const {
    environment, labour, animal,
  } = questions;
  // handles the change of the tab
  const handleChange = (event, newValue) => {
    changeValue(newValue);
  };

  const tabs = [
    {
      label: 'General',
      disable: false,
    },
    {
      label: 'Environment',
      disable: !size,
    },
    {
      label: 'Labour',
      disable: !size,
    },
    {
      label: 'Animal',
      disable: !size,
    },
    {
      label: 'Review',
      disable: ['Not started', 'In progress', 'Answered'].includes(riskStatus[0].status) || !riskStatus[0] || !size,
    },
    {
      label: 'Risk Assessment',
      disable: (riskStatus[0].status !== 'Verified' && riskStatus[0].status !== 'Verification skipped') || !size || !riskStatus[0],
    },
  ];

  const isVerified = () => riskStatus[0] && riskStatus[0].status === 'Verified';
  const disableSaveAnswersButton = riskStatus[0] && riskStatus[0].status === 'In verification';
  return (
    <Box sx={{ width: '100%', minHeight: 500 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs" className={classes.row} scrollButtons="auto">
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              {...a11yProps(tab.label)}
              className={classes.tab}
              disabled={tab.disable}
            />
          ))}
        </Tabs>
        { showSaveButton && !disableSaveAnswersButton && (
        <Grid iten align="right">
          <Button
            variant="contained"
            color="primary"
            disable={disableSaveAnswersButton}
            style={{
              marginRight: 24, marginTop: 24,
            }}
            onClick={() => { handleAnswers(answers, false); }}
          >
            Save answers
          </Button>
        </Grid>
        ) }
      </Box>

      {/* todo: refactor iteration */}

      <TabPanel
        value={value}
        index={0}
        className={classes.tabPanel}
        scrollButtons="auto"
      >
        <TabContentSize
          setSize={setSize}
          url={url}
          brandName={brandName}
          setIsCorrectURL={setIsCorrectURL}
          isCorrectURL={isCorrectURL}
          criteria={criteria}
          handleBrandStatus={handleBrandStatus}
          riskStatus={riskStatus.length > 0 && riskStatus[0].status}
          openForm={openForm}
          setOpenForm={setOpenForm}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className={classes.tabPanel}
      >
        <TabContentQuestions
          questions={environment}
          answers={answers}
          setAnswers={setAnswers}
          beAnswers={beAnswers}
          isQuestionAnswered={isQuestionAnswered}
          setUpdated={setUpdated}
          updated={updated}
          answersWithError={answersWithError}
          verificatedQuestions={verificatedQuestions}
          handleVerificationQuestions={handleVerificationQuestions}
          dependencies={dependencies}
          riskStatus={riskStatus.length > 0 && riskStatus[0].status}
          isVerified={isVerified}
          expanded={expanded}
          setExpanded={setExpanded}
          handleOutDatedAnswer={handleOutDatedAnswer}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        className={classes.tabPanel}
      >
        <TabContentQuestions
          questions={labour}
          answers={answers}
          setAnswers={setAnswers}
          beAnswers={beAnswers}
          isQuestionAnswered={isQuestionAnswered}
          setUpdated={setUpdated}
          updated={updated}
          answersWithError={answersWithError}
          verificatedQuestions={verificatedQuestions}
          handleOutDatedAnswer={handleOutDatedAnswer}
          handleVerificationQuestions={handleVerificationQuestions}
          riskStatus={riskStatus.length > 0 && riskStatus[0].status}
          dependencies={dependencies}
          isVerified={isVerified}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        className={classes.tabPanel}
      >
        <TabContentQuestions
          questions={animal}
          answers={answers}
          setAnswers={setAnswers}
          beAnswers={beAnswers}
          isQuestionAnswered={isQuestionAnswered}
          setUpdated={setUpdated}
          updated={updated}
          answersWithError={answersWithError}
          handleOutDatedAnswer={handleOutDatedAnswer}
          handleVerificationQuestions={handleVerificationQuestions}
          verificatedQuestions={verificatedQuestions}
          riskStatus={riskStatus.length > 0 && riskStatus[0].status}
          dependencies={dependencies}
          isVerified={isVerified}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={4}
        className={classes.tabPanel}
      >
        <TabContentReview
          questions={questions}
          answers={answers}
          setAnswers={setAnswers}
          beAnswers={beAnswers}
          isQuestionAnswered={isQuestionAnswered}
          setUpdated={setUpdated}
          updated={updated}
          value={value}
          changeValue={changeValue}
          answersWithError={answersWithError}
          verificatedQuestions={verificatedQuestions}
          isVerified={isVerified}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={5}
        className={classes.tabPanel}
      >
        <TabContentIndicators
          brandId={brandId}
          setUpdated={setUpdated}
          updated={updated}
        />
      </TabPanel>
    </Box>
  );
}

TabsHeader.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  setSize: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isQuestionAnswered: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  isCorrectURL: PropTypes.bool.isRequired,
  setIsCorrectURL: PropTypes.func.isRequired,
  setUpdated: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
  criteria: PropTypes.arrayOf(PropTypes.string).isRequired,
  riskStatus: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.object).isRequired,
  brandName: PropTypes.string.isRequired,
  handleBrandStatus: PropTypes.func.isRequired,
  handleVerificationQuestions: PropTypes.func.isRequired,
  verificatedQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  handleAnswers: PropTypes.func.isRequired,
  showSaveButton: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  handleOutDatedAnswer: PropTypes.func.isRequired,
};

export default TabsHeader;
