/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material UI
import MUIDataTable from 'mui-datatables';
import TuneIcon from '@material-ui/icons/Tune';

// local components
import { Button, Grid } from '@material-ui/core';
import GroupAvatars from '../../../components/groupAvatars/GroupAvatars';
import LinkExternal from '../../../components/common/link/LinkExternal';
import SimpleModal from '../../../components/common/modal/Modal';
import { settingsTerms as settings } from './helpers/tableSettings';
// styles
import useStyles from './styles';

const components = {
  icons: {
    FilterIcon: TuneIcon,
  },
};

export default function TableTerms({
  data,
  handleOpenCommentForm,
  getComments,
  setIsCommentFormOpen,
  searchValue,
}) {
  const classes = useStyles();
  const allCol = [];
  const [rowsSelected, setRowsSelected] = useState([]);
  const [termId, setTermId] = useState();
  const [prevTermId, setPrevTermId] = useState();
  const [update, setUpdate] = useState(false);
  settings.options.onRowClick = (row) => {
    handleOpenCommentForm(row[0]);
  };

  data.forEach((row) => {
    const {
      _id, term, frequency, url, context, comments,
    } = row;
    allCol.push([
      _id,
      term,
      frequency,
      <LinkExternal href={url} text={url} />,
      <SimpleModal contexHTML={context} term={term} />,
      <Button onClick={() => getComments(comments)}>
        <GroupAvatars comments={comments} />
      </Button>,
    ]);
  });

  useEffect(() => {
    if (termId && termId !== prevTermId) {
      setPrevTermId(termId);
      handleOpenCommentForm(termId);
    } if (termId === prevTermId) {
      setIsCommentFormOpen(false);
      setTermId('');
    }
  }, [update]);

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    setCellProps: () => ({ style: { textAlign: 'center' } }),
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: false,
    selectableRows: 'single',
    selectToolbarPlacement: 'none',
    searchOpen: true,
    searchText: searchValue,
    rowsSelected,
    onRowSelectionChange: (rowsSelecteda, allRows) => {
      setRowsSelected(allRows.map((row) => row.dataIndex));
      setTermId(data[rowsSelecteda[0].dataIndex]._id);
      setUpdate(!update);
    },
    customBodyRender: (value) => (<Grid align="center">{value}</Grid>),
    searchPlaceholder: 'Search . . .',
  };
  return (
    <MUIDataTable
      className={classes}
      data={allCol}
      {...{ components }}
      columns={settings.columns}
      options={options}
    />
  );
}
TableTerms.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  handleOpenCommentForm: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  setIsCommentFormOpen: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};
