/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// material UI
import { Grid, Typography, Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import TuneIcon from '@material-ui/icons/Tune';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import BlockIcon from '@material-ui/icons/Block';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
// local components
import Tooltip from '../../../components/common/tooltip/Tooltip';
import Link from '../../../components/common/link/Link';
import settings from './utils/tableSettings';
import StatusIconsGroup from '../components/StatusIconGroup';
// styles
import useStyles from './styles';

const components = {
  icons: {
    FilterIcon: TuneIcon,
  },
};

export default function TableRiskToolBrands({
  data, removeThisEntry,
}) {
  const classes = useStyles();
  const [itemSelected, setItemSelected] = useState('');
  const [open, setOpen] = useState(false);
  const allCol = [];

  // handle the click on the remove button
  const handleRemove = (id) => {
    setItemSelected(id);
    setOpen(true);
  };
  const filteredScraperStatus = (status) => {
    switch (status) {
      case 'Completed':
        return {
          icon: <CheckCircleOutlineRoundedIcon className={classes.checkIcon} />,
          text: status,
          tooltip: 'Completed: The scraping process is completed, you can now look at the results',
        };
      case 'Error':
        return {
          icon: <BlockIcon className={classes.blockIcon} />,
          text: status,
          tooltip: `Reviewed: Something happened before the process could have been completed. 
            The process has been stopped`,
        };
      case 'Running':
        return {
          icon: <RepeatRoundedIcon className={classes.running} />,
          text: status,
          tooltip: 'Scraped: Scraping the brand\'s pages',
        };
      case 'Queued':
        return {
          icon: <QueueRoundedIcon className={classes.queue} />,
          text: status,
          tooltip: 'Queued: before starting the scraping process is waiting for other processes to terminate',
        };
      case 'Website changed':
        return {
          icon: <PriorityHighRoundedIcon className={classes.blockIcon} />,
          text: status,
          tooltip: 'The Website has changed, please re-run the scraper',
        };
      default:
        return '';
    }
  };

  data.forEach((row) => {
    const {
      id, name, riskStatus, scraperStatus, createdAt, size,
    } = row;
    allCol.push([
      id,
      name,
      size,
      riskStatus[0],
      scraperStatus,
      createdAt,
    ]);
  });

  const { options } = settings();
  return (
    <MUIDataTable
      className={classes}
      data={allCol}
      {...{ components }}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            searchable: false,
            filter: false,
            display: 'excluded',
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            filter: false,
            searchable: true,
            customBodyRender: (value, tableMeta) => {
              const { rowData } = tableMeta;
              const id = rowData[0];
              return (
                <>
                  <Link
                    to={`/risk-tool/brand/${id}`}
                    text={value}
                  />
                </>
              );
            },
          },
        },
        {
          name: 'size',
          label: 'Brand Size',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value) => (
              <Grid item align="center">
                <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                  {value}
                </Typography>
              </Grid>
            ),
          },
        },
        {
          name: 'riskStatus',
          label: 'Risk Assessment Status',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value) => (
              <StatusIconsGroup statusData={value} />
            ),
          },
        },
        {
          name: 'scraperStatus',
          label: 'Scraper Status',
          options: {
            filter: true,
            searchable: false,
            // setCellProps: () => ({ style: { minWidth: 150, width: 300 } }),
            customBodyRender: (value) => (
              <Grid item align="center">
                <Tooltip text={filteredScraperStatus(value).tooltip}>
                  {filteredScraperStatus(value).icon}
                </Tooltip>
              </Grid>
            ),
          },
        },
        {
          name: 'date',
          label: 'Date',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value, tableMeta) => {
              const { rowData } = tableMeta;
              const id = rowData[0];
              return (
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body1">{moment(value).format('DD MMM YYYY')}</Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Tooltip text="Delete this result">
                      <DeleteOutlineRoundedIcon
                        className={classes.deleteBnt}
                        onClick={() => handleRemove(id)}
                      />
                    </Tooltip>
                  </Grid>
                  {open && id === itemSelected && (
                    <Grid container item lg={6} className={classes.removeAlertContainer} justifyContent="center">
                      <Grid item xs={8}>
                        <Typography variant="body1" align="center" className={classes.textWarning}>
                          Are you sure you want to delete this result?
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="space-between" item xs={8} align="right" wrap="nowrap">
                        <Grid item>
                          <Button variant="outlined" onClick={() => setOpen(false)}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={() => removeThisEntry(rowData[0])}>Delete</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            },
          },
        },
      ]}
      options={options}
    />
  );
}
TableRiskToolBrands.propTypes = {
  data: PropTypes.shape([]).isRequired,
  removeThisEntry: PropTypes.func.isRequired,
};
