import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  iconNotSelected: {
    color: theme.palette.primary.lighter,
    marginRight: 8,
  },
  iconSelected: {
    color: theme.palette.primary,
    marginRight: 8,
  },
}));
