const settings = (icon) => ({
  options: {
    download: false,
    customToolbar: () => icon,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    selectToolbarPlacement: 'none',
    searchOpen: 'true',
    searchPlaceholder: 'Search . . .',
    sortOrder: { name: 'date', direction: 'desc' },

  },
});

export default settings;
