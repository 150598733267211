import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop: '2rem',
  },
  scraperForm: {
    marginBottom: '3rem',
    display: 'flex',
    width: '100%',
    direction: 'row',
  },
  button: {
    transition: 'all 0.3s',
    height: 53,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  filterButton: {
    cursor: 'pointer',
  },
  warningMessageContainer: {
    backgroundColor: theme.palette.warning.light,
    marginTop: '1rem',
  },
  warningMessage: {
    color: theme.palette.warning.dark,
  },
}));
