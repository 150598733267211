import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { Modal, Button } from '@material-ui/core';

// styles
import useStyles from './styles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function SimpleModal({ contexHTML, term }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Context</h2>
      {contexHTML.map((context, index) => (
        <>
          <Highlighter
            // eslint-disable-next-line no-undef
            key={`${context}-${index * Math.random()}`}
            highlightClassName={classes.highlightClass}
            searchWords={[term]}
            autoEscape
            textToHighlight={context}
          />
          <div>
            --------------------------------------------------------------------------------
          </div>
        </>
      ))}
    </div>
  );

  return (
    <div>
      <Button
        className={classes.button}
        color="primary"
        type="button"
        onClick={handleOpen}
        size="small"
      >
        {!term ? 'No context' : term}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
SimpleModal.defaultProps = {
  term: '',
};
SimpleModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contexHTML: PropTypes.array.isRequired,
  term: PropTypes.string,
};
