import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// Material UI Components
import { Grid, Typography } from '@material-ui/core';
// styles
import useStyles from './styles';

const Card = ({
  pageCount, title, date, verdict, expert, approval,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item container xs={10} justifyContent="center">
          {pageCount ? (
            <Grid container justifyContent="center" item>
              <Typography className={classes.title} variant="caption">
                {title}
              </Typography>
              <Grid item alignItems="center">
                <Typography align="center" variant="h3">
                  {pageCount}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid Grid container item xs={12}>
              <Typography className={classes.title} variant="caption">
                {title}
              </Typography>
              <Grid
                item
                xs={12}
                className={[
                  classes[`verdictBox-${verdict}`],
                  classes.verdictBox,
                ]}
              >
                <Typography
                  variant="body2"
                  align="center"
                  className={[classes[`verdict-${verdict}`], classes.verdict]}
                >
                  {verdict}
                </Typography>
              </Grid>
              {expert && (
                <Grid item xs={12}>
                  {approval?.user && (
                    <Grid item>
                      <Typography display="inline" className={classes.approval}>
                        SSOT Approved
                      </Typography>
                    </Grid>
                  )}
                  <Typography variant="body1" display="inline">
                    Name:
                    {` ${approval ? approval.user.username : expert}`}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {date && (
                  <>
                    <Typography variant="body1" display="inline">
                      Date:
                      {` ${
                        approval
                          ? moment(approval.date).format('DD MMM YYYY')
                          : moment(date).format('DD MMM YYYY')
                      }`}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
Card.defaultProps = {
  expert: '',
  date: '',
  verdict: '',
  pageCount: 0,
  approval: '',
};
Card.propTypes = {
  title: PropTypes.string.isRequired,
  pageCount: PropTypes.number,
  date: PropTypes.string,
  verdict: PropTypes.string,
  expert: PropTypes.string,
  approval: PropTypes.shape({
    date: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
  }),
};
export default Card;
