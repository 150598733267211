import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import {
  Switch as SwitchReact,
} from '@material-ui/core';
// styles
import useStyles from './styles';

const Switch = ({ toogle, status }) => {
  const classes = useStyles();

  return (
    <SwitchReact
      color="primary"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={status}
      onChange={toogle}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      className={classes}
    />
  );
};
Switch.propTypes = {
  toogle: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
};
export default Switch;
