import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthRiskToolAPIsSingleton } from '../modules/api';

const UserStateContext = createContext();
const UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.accessToken,
        user: action.data.user,
      };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false, user: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('accessToken'),
    accessToken: localStorage.getItem('accessToken'),
    user: JSON.parse(localStorage.getItem('user')),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    AuthRiskToolAPIsSingleton.login(login, password)
      .then((r) => {
        const { data } = r;
        const { accessToken } = data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('user', JSON.stringify(data.user));
        setError(null);
        setIsLoading(false);
        dispatch({ type: 'LOGIN_SUCCESS', accessToken, data });
        history.push('/');
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
    // .then(() => AuthAPIsSingleton.login(login, password)
    //   .then((r) => {
    //     const { data } = r;
    //     const { accessToken } = data;
    //     localStorage.setItem('accessTokenScraper', accessToken);
    //     setError(null);
    //     setIsLoading(false);
    //     dispatch({
    //     });
    //     window.location.reload(false);
    //   })
    //   .catch(() => {
    //     setError(true);
    //     setIsLoading(false);
    //   }));
  } else {
    setError(true);
    setIsLoading(false);
  }
}

function resetPassword(email, setError, setIsLoading, setSuccess) {
  setError(false);
  setIsLoading(true);

  if (email) {
    AuthRiskToolAPIsSingleton.resetPassword(email)
      .then(() => {
        setError(null);
        setIsLoading(false);
        setSuccess(true);
      }).catch(() => {
        setError(true);
        setIsLoading(false);
      });
  } else {
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  if (history) {
    history.push('/login');
  }
}

UserProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};
export {
  UserProvider, useUserState, useUserDispatch, loginUser, signOut, resetPassword,
};
