/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components material ui
import {
  Grid, Typography, FormGroup, Checkbox,
  IconButton, FormControlLabel, FormControl, RadioGroup, Radio, Button,
} from '@material-ui/core';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import { generalQuestions } from '../../../../../data/risk-indicator-first';
// styles
import useStyles from './styles';

function TabContentSize({
  setSize, url, isCorrectURL, setIsCorrectURL, criteria, brandName, handleBrandStatus, riskStatus,
  openForm,
  setOpenForm,
}) {
  const classes = useStyles();
  const { question, answers } = generalQuestions;

  // local
  const [selecetedAnswers, setSelectedAnswers] = useState(criteria);
  const [status, setStatus] = useState(riskStatus);
  const [isStatusOpen, setIsStatusOpen] = useState(true);
  const isSmall = selecetedAnswers.includes('none') || selecetedAnswers.includes('goy-small');
  const isLarge = !isSmall && selecetedAnswers.length > 0;

  //  handlers
  const handleSelectAnswer = (event) => {
    const { value, checked } = event.target;
    if (checked && value === 'none') {
      return setSelectedAnswers(['none']);
    } if (checked && value === 'goy-small') {
      const indexNull = selecetedAnswers.indexOf('none');
      // then removes it
      if (indexNull > -1) setSelectedAnswers(selecetedAnswers.splice(indexNull, 1));
      return setSelectedAnswers([...selecetedAnswers, 'goy-small']);
    } if (checked && value !== 'none') {
      // check first if none is selected
      const indexNull = selecetedAnswers.indexOf('none');
      // then removes it
      if (indexNull > -1) setSelectedAnswers(selecetedAnswers.splice(indexNull, 1));
      // then add the large values
      return setSelectedAnswers([...selecetedAnswers, value]);
    }
    return setSelectedAnswers(selecetedAnswers.filter((item) => item !== value));
  };

  const handleSelectURL = (event) => {
    const { checked } = event.target;
    if (checked) {
      return setIsCorrectURL(true);
    }
    return setIsCorrectURL(false);
  };

  // effects
  useEffect(() => {
    setSize(selecetedAnswers);
    if (selecetedAnswers.length === 0) {
      setSize(null);
    }
  }, [selecetedAnswers]);

  const test = false;
  return (
    <>
      <Grid container className={classes.container}>
        { test && (
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title} onClick={() => setIsStatusOpen(!isStatusOpen)}>
            Status
          </Typography>
          {isStatusOpen && (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="size"
              name="size"
              onChange={(e) => { setStatus(e.target.value); }}
              className={classes.group}
              defaultValue={status}
              row
            >
              <FormControlLabel
                value="In progress"
                control={<Radio color="primary" />}
                label="In progress"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Answered"
                control={<Radio color="primary" />}
                label="Answered"
                labelPlacement="end"
              />
              <FormControlLabel
                value="In verification"
                control={<Radio color="primary" />}
                label="In Verification"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Verification skipped"
                control={<Radio color="primary" />}
                label="Verification Skipped"
                labelPlacement="end"
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleBrandStatus(status)}
              >
                Save
              </Button>
            </RadioGroup>
          </FormControl>
          )}
        </Grid>
        )}
      </Grid>

      <Grid
        container
        className={classes.root}
        direction="column"
      >
        {/* check url */}
        <Grid item xs={12} style={{ padding: '2rem 0' }}>
          <Typography variant="h6">
            Is
            {' '}
            {brandName}
            &apos;s URL correct&apos; ?
          </Typography>
          <Typography variant="caption">
            If not please edit top left of this page
          </Typography>
          <IconButton size="small" color="primary" variant="contained" onClick={() => setOpenForm(!openForm)}>
            <EditRoundedIcon style={{ fontSize: '1rem' }} />
            {' '}
          </IconButton>
        </Grid>
        <Grid item container alignItems="center" style={{ paddingBottom: '1rem' }}>
          <Typography
            variant="body2"
            display="inline"
            style={{
              border: '1px solid #222', inlineSize: 'fit-content', padding: '4px 8px', borderRadius: 4, marginRight: 16,
            }}
          >
            {url}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={12} xl={3}>
            <Grid item container alignContent="center" xs={11}>
              <Grid item>
                <FormControlLabel
                  value={url}
                  checked={isCorrectURL}
                  control={(<Checkbox disableRipple color="primary" onChange={((event) => handleSelectURL(event))} />)}
                  label="I confirm that the above URL is correct"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '2rem 0' }}>
          <Typography variant="h6">What is the size of the Brand?</Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
          <Typography variant="body2">{question}</Typography>
        </Grid>
        <Grid item container alignContent="center" xs={12}>

          {/* answers list */}
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              {answers.map((answer) => (
                <Grid item container xs={12} key={answer.id}>
                  <FormControlLabel
                    value={answer.criteria}
                    control={(<Checkbox color="primary" onChange={((event) => handleSelectAnswer(event))} />)}
                    label={answer.text}
                    checked={selecetedAnswers.includes(answer.criteria)}
                    labelPlacement="end"
                  />
                </Grid>
              ))}
            </FormGroup>
          </FormControl>

          {/* response */}
          <Grid item style={{ paddingTop: '2rem' }}>
            <Typography variant="h6">Brand Size: </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12} xl={3}>
              <Grid item xs={1}>
                <Radio disabled checked={isSmall} value="SMALL" />
                {' '}
              </Grid>
              <Grid item container alignContent="center" xs={11}>
                <Typography className={isSmall && classes.selected} variant="body1">SME - Small and Medium Enterprise</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} xl={3}>
              <Grid item xs={1}>
                <Radio disabled checked={isLarge} value="LARGE" />
                {' '}
              </Grid>
              <Grid item container alignContent="center" xs={11}>
                <Typography className={isLarge && classes.selected} variant="body1">Large</Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* end response */}
        </Grid>

      </Grid>
    </>
  );
}

TabContentSize.propTypes = {
  history: PropTypes.shape({}).isRequired,
  setSize: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  isCorrectURL: PropTypes.bool.isRequired,
  setIsCorrectURL: PropTypes.func.isRequired,
  criteria: PropTypes.arrayOf(PropTypes.string).isRequired,
  brandName: PropTypes.string.isRequired,
  handleBrandStatus: PropTypes.func.isRequired,
  riskStatus: PropTypes.string.isRequired,
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
};
export default TabContentSize;
