import React from 'react';

// Material UI Components
import {
  CircularProgress, Grid,
} from '@material-ui/core';
// styles
import useStyles from './styles';

const Loader = () => {
  const classes = useStyles();
  return (

    <Grid container className={classes.root} justifyContent="center" alignItems="center">
      <Grid container item className={classes.container} justifyContent="center" alignItems="center">
        <Grid xs={12} container item className={classes.box} justifyContent="center" alignItems="center">
          <CircularProgress size="18px" className={classes.loader} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Loader;
