import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({

  //  form
  containerForm: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '1rem',
    marginLeft: '1rem',
    border: `1px solid ${theme.palette.primary.light}`,
    position: 'fixed',
    width: '250px',
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '220px',
    },
  },
  containerAuthor: {
    width: '100%',
  },
  buttonsComment: {
    flexWrap: 'wrap',
  },
  buttonConfirm: {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    marginBottom: 8,
  },
  // single comment

  containerComment: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '1rem',
    position: 'relative',
    marginBottom: '1rem',
    boxShadow: theme.customShadows.lg,
    minWidth: 180,
  },
  overlayComment: {
    backgroundColor: '#595454d6',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '15px 15px 0 15px',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    color: 'white',
    left: 0,
    top: 0,
    padding: '.5rem',
  },
  buttonConfirmDelete: {
    color: 'white',
    border: `1px solid ${theme.palette.error.dark}`,
    backgroundColor: theme.palette.error.main,
  },
  buttonCancelDelete: {
    color: 'white',
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.primary.main,
  },
  closeComments: {
    padding: 0,
    marginLeft: '2rem',
  },
}));
