/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components material ui
import {
  Grid, Typography, Checkbox, FormGroup, FormControlLabel, FormControl,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Loader from '../../../../../components/common/loader/Loader';
import { BrandsRiskToolSingleton as APIBrandsRisk, AuthRiskToolAPIsSingleton2 } from '../../../../../modules/api';

// context
import { useUserDispatch, useUserState } from '../../../../../context/UserContext';
// components local
import AccordionContentReview from '../AccordionContentReview';
// styles
import useStyles from './styles';

function TabContentIndicators({
  brandId, setUpdated, updated,
}) {
  const classes = useStyles();
  //  context state
  const { accessToken } = useUserState();

  //  local state
  const [loading, setLoading] = useState(true);
  const [indicators, setIndicators] = useState([]);

  const assignColor = (value) => {
    if (value === 'Yes') {
      return classes.successString;
    }
    if (value === 'Partially') {
      return classes.partiallyString;
    }
    if (value === 'No') {
      return classes.negativeString;
    }
    return classes.gray;
  };

  useEffect(() => {
    AuthRiskToolAPIsSingleton2.getRiskIndicators(accessToken, brandId)
      .then((r) => {
        const { data } = r;
        setIndicators(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          console.log('logout');
        }
      });
  }, []);

  if (indicators.length === 0) return <Loader />;

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="center"
      alignItems="center"
      item
      xs={12}
      xl={9}
    >
      <Grid item>
        <Typography variant="h5" className={classes.title}>
          Risk Assessment
        </Typography>
      </Grid>
      {indicators.map((indicator) => (
        <Grid item container justify="space-between" key={indicator.id} className={classes.indicatorContainer}>
          <Typography className={classes.indicatorText} variant="body2">
            {indicator.name}
          </Typography>
          <Typography className={assignColor(indicator.value)} align="center">
            {indicator.value || 'N/A'}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

TabContentIndicators.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  brandId: PropTypes.string.isRequired,
  setUpdated: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
};
export default TabContentIndicators;
