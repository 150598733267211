import AuthAPIs from './Auth';
import BrandsAPIs from './Brands';
import JobsAPIs from './Jobs';
import CommentsAPIs from './Comments';
import VerdictAPIs from './Verdict';
import ApprovalsAPIs from './Approvals';
import RiskToolAPIs from './RiskTool';
import AuthRiskAPIs from './AuthRiskTool';
import RiskToolAPI2s from './RiskTool2';

import getConfigForEnvironment from '../../config/getConfigForEnvironment';

const AuthAPIsSingleton = new AuthAPIs(getConfigForEnvironment());
const AuthRiskToolAPIsSingleton = new AuthRiskAPIs(getConfigForEnvironment());
const BrandsAPIsSingleton = new BrandsAPIs(getConfigForEnvironment());
const JobsAPIsSingleton = new JobsAPIs(getConfigForEnvironment());
const CommentsAPIsSingleton = new CommentsAPIs(getConfigForEnvironment());
const VerdictAPIsSingleton = new VerdictAPIs(getConfigForEnvironment());
const ApprovalsAPIsSingleton = new ApprovalsAPIs(getConfigForEnvironment());
const BrandsRiskToolSingleton = new RiskToolAPIs(getConfigForEnvironment());
const AuthRiskToolAPIsSingleton2 = new RiskToolAPI2s(getConfigForEnvironment());

export {
  AuthAPIsSingleton,
  BrandsAPIsSingleton,
  JobsAPIsSingleton,
  CommentsAPIsSingleton,
  VerdictAPIsSingleton,
  ApprovalsAPIsSingleton,
  BrandsRiskToolSingleton,
  AuthRiskToolAPIsSingleton,
  AuthRiskToolAPIsSingleton2,
};
