import React from 'react';

// import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

const fontSize = '1.75rem';

const menuItems = [
  {
    id: 'scraper',
    name: 'Scraper',
    icon: <SearchOutlinedIcon style={{ fontSize }} />,
    link: 'scraper-tool',
  },
  {
    id: 'risktool',
    name: 'Risk Tool',
    icon: <TrendingUpRoundedIcon style={{ fontSize }} />,
    link: 'risk-tool',
  },
];

export default menuItems;
