import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  box: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
