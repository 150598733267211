export const LOG_LEVEL_ERROR = 'ERROR';

export const errors = {
  NO_URL_PROVIDED: 'URL not provided for some answers',
};

export const logError = (message, context = null) => {
  let errorString = `[error] ${message}`;
  if (context) {
    errorString = `[${context}] ${errorString}`;
  }
  // eslint-disable-next-line no-console
  console.warn(errorString);
};
