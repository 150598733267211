import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    padding: '0 1rem',
    height: '100%',
  },
  containerHeadbar: {
    justifyContent: 'center',
    marginTop: '3rem',
  },

  col: {
    padding: '2.5rem 1rem',
  },
  containerClock: {
    maxWidth: 1360,
    height: '2rem',
  },
  containerPage: {
    alignSelf: 'flex-start',
    minHeight: '100%',
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15,
    },
  },
}));
