import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  container: {
    padding: '2rem',
    minHeight: '100%',
    alignSelf: 'flex-start',
  },
  cardsContainer: {
    padding: '1rem',
    border: '1px dashed grey',
    borderRadius: 10,
  },
  containerCard: {
    backgroundColor: 'white',
    padding: '.5rem',
    borderRadius: 10,
  },
  switchContainer: {
    padding: '1rem 0',
  },
}));
