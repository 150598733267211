/* eslint-disable no-console */
import axios from 'axios';
import {
  logError,
} from '../logger';

export default class Api {
  constructor(config) {
    this.config = config;
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_SCRAPER_API,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    // TODO Intercept auth tokens?
    this.instance.interceptors.response.use(
      (response) => response,
      // eslint-disable-next-line consistent-return
      async (error) => {
        // Error
        if (error.response && error.response.status) {
          const { status, data } = error.response;
          if (status === 401) {
            if (data && data.message && data.message === 'Invalid login') {
              console.log('Wrong username/password');
            } else {
              console.log('Session expired');
              return Promise.reject(new Error('LOGOUT'));
            }
            return Promise.reject(error);
          }
          if (status === 500) {
            // TODO API OFFLINE MESSAGE
          }
          if (status === 403) {
            // TODO something with unauthorized
          }
          if (status === 404) {
            // TODO 404 is server down!
          }
        } else if (error.request) {
          // TODO review here
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          logError('error.request', 'axios');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Empty error response', error.message);
        }
      },
    );
  }

  static addAuthorizationHeader(authToken) {
    return { headers: { Authorization: `Bearer ${authToken}` } };
  }
}
