import tinycolor from 'tinycolor2';

const primary = '#222';
const secondary = '#222';
const warning = '#F59E0B';
const success = '#10B981';
const error = '#EF4444';
const info = '#6183e4';

const lightenRate = 29.5;
const darkenRate = 15;

export default {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
      lighter: tinycolor(primary)
        .lighten(lightenRate * 2.5),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
      light: 'rgba(255, 245, 229, 1)',
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: '#D1FAE5',
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    error: {
      main: error,
      light: 'rgba(254, 236, 235, 1)',
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    grey: {
      50: '#F8FAFC',
      100: '#F1F5F9',
      200: '#E2E8F0',
      300: '#CBD5E1',
      400: '#94A3B8',
      500: '#64748B',
      600: '#475569',
      700: '#334155',
      800: '#1E293B',
      900: '#0F172A',
      A100: '#d5d5',
      A200: '#aaaa',
      A400: '#3030',
      A700: '#6161',
    },
    text: {
      primary: '#222222',
      secondary,
      hint: '#222222',
    },
    placeholder: '#9DA2B978',
    background: {
      default: '#f5f3f1',
      light: '#F3F5FF',
    },
    spacing: 4,
  },
  customShadows: {
    none:
      'none',
    xs:
      '0px 4px 8px rgba(0,0,0,0.08)',
    sm:
      '0px 6px 12px rgba(0,0,0,0.11)',
    md:
      '0px 9px 18px rgba(0,0,0,0.15)',
    lg:
      '0px 13px 37px rgba(0,0,0,0.21)',
    xl:
      '0px 20px 56px rgba(0,0,0,0.29)',

  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9',
      },
    },
    MuiDrawer: {
      paper: {
        width: 125,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F3F5FF !important',
          '&:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
        paddingLeft: 24,
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
  },
};
