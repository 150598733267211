import React, { useState, useEffect } from 'react';
import {
  Grid,
} from '@material-ui/core';

// Local components
import TableRiskToolBrands from './tables/TableRiskToolBrands';
import { useUserDispatch, signOut, useUserState } from '../../context/UserContext';
import { AuthRiskToolAPIsSingleton2 } from '../../modules/api';
// styles
import useStyles from './styles';
// eslint-disable-next-line react/prop-types
export default function Scraper({ history }) {
  const classes = useStyles();
  const { accessToken } = useUserState();
  const userDispatch = useUserDispatch();

  // local
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  //  handles
  const handleRemoveEnetry = (id) => {
    AuthRiskToolAPIsSingleton2.deleteBrand(accessToken, id)
      .then(() => {
        setUpdate(!update);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e && e.message && e.message === 'LOGOUT') {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
  };
  useEffect(() => {
    AuthRiskToolAPIsSingleton2.getAllBrands(accessToken).then((r) => {
      const { data } = r;
      setBrands(data);
    })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
  }, [update]);

  return (
    isLoading ? <div>Loading</div> : (
      <Grid container className={classes.container}>
        <Grid justifyContent="space-between" item container alignItems="flex-end">
          <Grid container item xs={12} style={{ minHeight: '66vh' }}>
            <TableRiskToolBrands
              data={brands}
              removeThisEntry={(handleRemoveEnetry)}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
