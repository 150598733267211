import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import {
  Chip as ReactChip,
} from '@material-ui/core';
// styles
import useStyles from './styles';

const Chip = ({ label }) => {
  const classes = useStyles();
  const style = label ? `chip-${label}` : 'chipnone';
  return (

    <ReactChip size="small" label={label} className={classes[style]} />
  );
};

Chip.defaultProps = {
  label: '',
};

Chip.propTypes = {
  label: PropTypes.string,
};
export default Chip;
