import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const BrandStateContext = createContext();
const BrandDispatchContext = createContext();

function brandReducer(state, action) {
  switch (action.type) {
    case 'SET_VERIFIED_ANSWERS':
      return {
        ...state,
        verifiedAnswers: action.verifiedAnswers.verifiedAnswers,
        answers: action.verifiedAnswers.answers,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BrandProvider({ children }) {
  const [state, dispatch] = useReducer(brandReducer, {
    answers: [],
    verifiedAnswers: [],
  });

  return (
    <BrandStateContext.Provider value={state}>
      <BrandDispatchContext.Provider value={dispatch}>
        {children}
      </BrandDispatchContext.Provider>
    </BrandStateContext.Provider>
  );
}

function useBrandState() {
  const context = useContext(BrandStateContext);
  if (context === undefined) {
    throw new Error('useBrandState must be used within a BrandProvider');
  }
  return context;
}

function useBrandDispatch() {
  const context = useContext(BrandDispatchContext);
  if (context === undefined) {
    throw new Error('useBrandDispatch must be used within a BrandProvider');
  }
  return context;
}
function dispatchAnswers(dispatch, verifiedAnswers) {
  dispatch({ type: 'SET_VERIFIED_ANSWERS', verifiedAnswers });
}
BrandProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};
export {
  BrandProvider, useBrandState, useBrandDispatch, dispatchAnswers,
};
