// local components
// Material UI Components
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
/* eslint-disable no-console */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  ApprovalsAPIsSingleton as APIApproval,
  VerdictAPIsSingleton as APIVerdict,
} from '../../modules/api';
import filterSelectOptions from './helpers/functions';
// styles
import useStyles from './styles';
import { useUserState } from '../../context/UserContext';

const GroupSelectVerdict = ({
  updateJob,
  jobId,
  scraperVerdict,
  expertVerdict,
}) => {
  const classes = useStyles();
  const { accesToken } = useUserState();
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [typeAction, setTypeAction] = useState('');
  const [commentVerdict, setCommentVerdict] = useState('');
  const {
    confirmAction,
    adjustAction,
  } = filterSelectOptions(expertVerdict?.outcome || scraperVerdict);

  //  handles
  const handleChangeType = (event) => {
    setTypeAction(event.target.value);
  };

  const handleChangeComment = (event) => {
    setCommentVerdict(event.target.value);
  };
  //  Api
  const handleSubmission = () => {
    setIsLoading(true);
    if (!typeAction) {
      setIsLoading(false);
      return;
    }
    if (typeAction === 'SSOT') {
      APIApproval.postApproval(accesToken, jobId, expertVerdict.outcome)
        .then(() => {
          setIsLoading(false);
          updateJob();
        })
        .catch((e) => {
          setIsLoading(false);
          if (e && e.message && e.message === 'LOGOUT') {
            console.log(e);
          }
        });
    } else {
      APIVerdict.postVerdic(accesToken, jobId, typeAction, commentVerdict)
        .then(() => {
          setIsLoading(false);
          updateJob();
        })
        .catch((e) => {
          setIsLoading(false);
          if (e && e.message && e.message === 'LOGOUT') {
            console.log(e);
          }
        });
    }
  };
  return (
    <Grid container className={classes.formControlComment} style={{ justifyContent: 'center' }}>
      <Grid item xs={12} style={{ padding: '8px' }}>
        <Typography variant="caption" align="center">Insert Verdict</Typography>
      </Grid>
      <Grid item container direction="column" xs={12}>
        <FormControl variant="outlined" className={classes.formControlComment}>
          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Type"
            fullwidth="true"
            onChange={handleChangeType}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={confirmAction.value}>{confirmAction.text}</MenuItem>
            <MenuItem value={adjustAction.value}>{adjustAction.text}</MenuItem>
            <MenuItem value="SSOT">Send to SSOT</MenuItem>
          </Select>
          <TextField
            id="type"
            margin="normal"
            type="text"
            fullwidth="true"
            variant="outlined"
            label="Comment"
            onChange={handleChangeComment}
            style={{ margin: '12px 0px 0px 0px' }}
          />
          <Button
            disabled={isLoading}
            size="small"
            variant="outlined"
            onClick={() => handleSubmission()}
            className={classes.verdictBtn}
          >
            Send
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
};

GroupSelectVerdict.propTypes = {
  scraperVerdict: PropTypes.string.isRequired,
  expertVerdict: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  updateJob: PropTypes.func.isRequired,
};
export default GroupSelectVerdict;
