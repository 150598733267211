import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'scroll',
    borderRadius: 10,
  },

  highlightClass: {
    backgroundColor: 'yellow',
  },
  button: {
    '& span': {
      fontSize: 10,
      textAlign: 'left',
    },
  },
}));
