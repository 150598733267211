/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';// Material Ui
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// components material ui
import { Grid } from '@material-ui/core';

// local components
import SubHeader from './components/subheader/SubHeader';
import InfoColumn from './components/infoColumn/InfoColumn';
import Questionaire from './components/questionaire/Questionaire';
import { errors } from '../../modules/logger';
import { JobsAPIsSingleton as APIJobs, AuthRiskToolAPIsSingleton2 } from '../../modules/api';
import {
  useUserDispatch,
  signOut,
  useUserState,
} from '../../context/UserContext';
import {
  useBrandState,
} from '../../context/BrandContext';

import Loader from '../../components/common/loader/Loader';
// styles
// import useStyles from './styles';

function Brand({ match, history }) {
  // const classes = useStyles();
  const { accessToken } = useUserState();
  const { params: { id } } = match;
  const { location: { state } } = history;
  const userDispatch = useUserDispatch();
  const { answers } = useBrandState();
  // local state
  const [brand, setBrand] = useState();
  const [scraperData, setScraperData] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [size2, setSize2] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [beAnswers, setBeAnswers] = useState([]);
  const [index, setIndex] = useState(state ? state.index : 0);
  const [answersWithError, setAnswersWithError] = useState([]);
  const [answersLocal, setAnswers] = useState(beAnswers);
  const [openForm, setOpenForm] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const [initialVerified, setInitialVerified] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  // API calls
  useEffect(() => {
    AuthRiskToolAPIsSingleton2.getBrand(accessToken, id)
      .then((r) => {
        const { data } = r;
        setBrand(data);
        setSize2(data.size);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
    if (brand && brand.size !== null) {
      const numberId = Number(id);

      AuthRiskToolAPIsSingleton2.getQuestionnaire(accessToken, brand.size)
        .then((r) => {
          const { data } = r;
          setQuestionnaire(data.qa);
          setDependencies(data.dependencies);
        })
        .catch((e) => {
          if (e && e.message && e.message === 'LOGOUT') {
            signOut(userDispatch, history);
          }
        });

      AuthRiskToolAPIsSingleton2.getAnswers(accessToken, numberId)
        .then((r) => {
          const { data } = r;
          setBeAnswers(data);
        })
        .catch((e) => {
          if (e && e.message && e.message === 'LOGOUT') {
            signOut(userDispatch, history);
          }
        });
      if (brand) {
        APIJobs.getSpecificJob(accessToken, brand.scraperJobId)
          .then((r) => {
            const { data } = r;
            setScraperData(data.overview);
          })
          .catch((e) => {
            if (e && e.message && e.message === 'LOGOUT') {
              console.log('LOGOUT');
            }
          });
      }
    }
  }, [updated, size2]);

  useEffect(() => {
    const fixedDataFromBe = beAnswers.map((item) => ({
      ...item,
    }));
    setAnswers(fixedDataFromBe || []);
  }, [beAnswers]);

  // HANDLES!
  const handleSize = (size) => {
    if (!size) {
      setUpdated(!updated);
    }
    enqueueSnackbar('Saving Brand Size . . .', { autoHideDuration: 1500 });

    // if size selection is not changed, dont' call the api
    if (size === brand.criteria) {
      setUpdated(!updated);
    }
    // else call the api
    setLoading(true);
    const numberId = Number(id);
    return AuthRiskToolAPIsSingleton2.setBrandSize(accessToken, numberId, size)
      .then(() => {
        enqueueSnackbar('Brand Size Saved!', { autoHideDuration: 1500 });
        setLoading(false);
        setUpdated(!updated);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
          setLoading(false);
        }
        setLoading(false);
      });
  };
  const handleNewUrl = (website, cbOpenForm) => {
    setLoading(true);
    const data = {
      website,
    };
    AuthRiskToolAPIsSingleton2.patchBrandUrl(accessToken, id, data)
      .then(() => {
        setLoading(false);
        setUpdated(!updated);
        enqueueSnackbar('New URL saved correctly!', { autoHideDuration: 1500, variant: 'success' });
        cbOpenForm(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
          setLoading(false);
        }
      });
  };

  const handleBrandStatus = (status) => {
    setLoading(true);
    const data = {
      status,
      brand: Number(id),
      comment: '',
    };
    AuthRiskToolAPIsSingleton2.postRiskStatus(accessToken, data)
      .then(() => {
        setLoading(false);
        setUpdated(!updated);
        enqueueSnackbar(`New Status  - ${status} - saved correctly!`, { autoHideDuration: 1500, variant: 'success' });
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(`Error: ${e.message}`, { autoHideDuration: 1500, variant: 'error' });
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
          setLoading(false);
        }
      });
  };

  const handleAnswers = (a, verification) => {
    setLoading(true);
    const data = {
      verification,
      brand: Number(id),
      answers: a === 'copy' ? answers : a,
    };
    const messageWarning = verification ? 'Submitting verification . . .' : 'Saving Answers . . .';
    const messageSuccess = verification ? 'Verification Submitted!' : 'Answers Saved!';
    enqueueSnackbar(messageWarning, { autoHideDuration: 1500 });

    AuthRiskToolAPIsSingleton2.postAnswers(accessToken, data)
      .then(() => {
        enqueueSnackbar(messageSuccess, { autoHideDuration: 1500 });
        setLoading(false);
        setAnswersWithError([]);
        setUpdated(!updated);
        setExpanded(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e.message === errors.NO_URL_PROVIDED) {
          setAnswersWithError(e.data);
        }
        return enqueueSnackbar(e.message, { autoHideDuration: 1500, variant: 'error' });
      });
  };
  if (!brand || !questionnaire) return <Loader />;

  const showVerificationCompletedButton = answers && answers.length > 0
  && answers.filter((a) => a.status === 'Verified').length === answersLocal.length;

  console.log(answers.filter((a) => a.status === 'Verified').length, answersLocal.length);
  return (
    <Grid
      container
    >
      <SubHeader
        brand={brand}
        brandURL={brand.website}
        size={brand.size}
        handleNewUrl={handleNewUrl}
        loading={loading}
        handleBrandStatus={handleBrandStatus}
        handleAnswers={handleAnswers}
        answers={answersLocal}
        openForm={openForm}
        setOpenForm={setOpenForm}
        showVerificationCompletedButton={showVerificationCompletedButton}
      />
      <Grid container wrap="nowrap" style={{ paddingTop: '3rem' }}>
        <Grid item wrap="nowrap" xs={4} lg={3}>
          <InfoColumn index={index} brand={brand} scraperData={scraperData} />
        </Grid>
        <Grid itemx xs={9} lg={9} style={{ paddingLeft: '1rem' }}>
          <Questionaire
            questions={questionnaire}
            dependencies={dependencies}
            size={brand.size}
            handleSize={handleSize}
            handleAnswers={handleAnswers}
            loading={loading}
            beAnswers={beAnswers}
            url={brand.website}
            setUpdated={setUpdated}
            setIndex={setIndex}
            index={index}
            criteria={brand.criteria || []}
            riskStatus={brand.riskStatus}
            brandId={id}
            answersWithError={answersWithError}
            brand={brand}
            handleBrandStatus={handleBrandStatus}
            answersLocal={answersLocal}
            setAnswers={setAnswers}
            openForm={openForm}
            setOpenForm={setOpenForm}
            expanded={expanded}
            setExpanded={setExpanded}
            // verificatedQuestionsUp={initialVerified}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

Brand.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        index: PropTypes.number,
      }),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
};
export default withRouter(Brand);
