const filterSelectOptions = (scraperVerdict) => {
  switch (scraperVerdict) {
    case 'info':
      return {
        confirmAction: {
          text: 'Confirm Info', value: 'info',
        },
        adjustAction: {
          text: 'Adjust to No Info', value: 'no-info',
        },
      };
    case 'no-info':
      return {
        confirmAction: {
          text: 'Confirm No Info', value: 'no-info',
        },
        adjustAction: {
          text: 'Adjust to Info', value: 'info',
        },
      };
    case 'probably-info':
      return {
        confirmAction: {
          text: 'Confirm to Info', value: 'info',
        },
        adjustAction: {
          text: 'Confirm No Info', value: 'no-info',
        },
      };
    case 'undecided':
      return {
        confirmAction: {
          text: 'Confirm to Info', value: 'info',
        },
        adjustAction: {
          text: 'Confirm No Info', value: 'no-info',
        },
      };
    default:
      return '';
  }
};

export default filterSelectOptions;
