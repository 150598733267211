import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

import SyncIcon from '@material-ui/icons/Sync';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    boxShadow: theme.customShadows.sm,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  info: {
    color: theme.palette.success.main,
  },
  'no-info': {
    color: theme.palette.error.main,
  },
  ssot: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export default function TimelineHistory({ historyVerdicts }) {
  const classes = useStyles();
  return (

    historyVerdicts && historyVerdicts.length > 0 ? (
      historyVerdicts.map((entry, index) => {
        const {
          outcome, date, text, user, approval,
        } = entry;
        const alternate = (index % 2) !== 0 ? 'left' : 'right';
        return (
          <Timeline align={alternate}>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2">
                  {moment(date).format('DD MMM \'YY')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {approval ? (
                  <TimelineDot variant="outlined" color="secondary">
                    <EnhancedEncryptionIcon />
                  </TimelineDot>
                )
                  : (
                    <TimelineDot variant="outlined" color="secondary">
                      { index !== 0 ? <SyncIcon /> : <CheckCircleOutlineRoundedIcon />}
                    </TimelineDot>
                  )}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" className={`${classes[outcome]}`}>
                    {outcome === 'no-info' ? 'No Info' : 'Info'}
                  </Typography>
                  {approval ? (
                    <Typography className={classes.ssot}>{`${user.username}: Approved to SSOT`}</Typography>
                  ) : (<Typography>{`${user.username} - ${text}`}</Typography>)}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        );
      })
    ) : 'No History'
  );
}
