/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// components material ui
import {
  Typography, Grid, IconButton, Checkbox,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from '../../../../components/common/loader/Loader';

// components local
import Evidence from './main/Evidence';
// styles
import useStyles from './styles';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = ({ answers, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <MuiAccordionSummary
        style={{ backgroundColor: '#f5f3f1', cursor: 'default' }}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
        className={classes.accordionSummary}
      />
      {answers.length > 0 && answers.map((answer) => (
        <Grid
          container
          item
          xs={12}
          xl={10}
          alignItems="center"
          className={answer.status === 'Outdated' ? classes.answerContainerOutdated : classes.answerContainer}
          justifyContent="space-between"
        >
          <Typography variant="body1" className={classes.answerText}>
            -
            {' '}
            {answer.text}
          </Typography>
          <Grid item xs={12} style={{ paddingTop: '.5rem' }} align="right">
            <IconButton disabled size="small">
              <PersonRoundedIcon />
            </IconButton>
            <Typography variant="caption" className={classes.details}>
              {answer.user.username}
            </Typography>
            <IconButton disabled size="small">
              <TodayRoundedIcon />
            </IconButton>
            <Typography variant="caption" className={classes.details}>
              {moment(answer.date).format('DD MMM YYYY')}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  backgroundColor: 'f8fafc',
}));

function AccordionContent({
  questions,
  answers,
  beAnswers,
  slugDependenciesQuestion,
  slugDependenciesAnswer,
  answersWithError,
  verificatedQuestions,
  isVerified,
}) {
  // local

  // eslint-disable-next-line no-unused-vars
  if (!questions || !beAnswers) return <Loader />;

  const showQuestion = (question) => {
    if (question.dependencies) {
      return question.dependencies.every((dep) => slugDependenciesQuestion.includes(dep));
    }
    return true;
  };
  const hasArrowToBeRed = (questionAnswers) => {
    if (answersWithError.length > 0 && answersWithError
      .some((a) => questionAnswers.filter((qa) => qa.id === a).length > 0)) {
      return true;
    }
    return false;
  };
  const showAnswer = (answer) => {
    if (answer.dependency) {
      return slugDependenciesAnswer.includes(answer.dependency);
    }
    return true;
  };

  const isASavedAnswer = (question) => {
    const questionsAnswersId = question.answers.map((a) => a.id);
    const compositeArray = [];
    if (answers.length > 0 && answers.some((a) => questionsAnswersId.includes(a.id))) {
      answers.map((a) => {
        if (questionsAnswersId.includes(a.id)) {
          return compositeArray.push({
            ...a,
            text: question.answers.find((ans) => ans.id === a.id).text,
          });
        }
        return compositeArray;
      });
    }
    return compositeArray;
  };

  return (
    <>

      {
       beAnswers && answers && questions.map((q) => (
         showQuestion(q) && (
         <Accordion
           expanded={false}
           key={q.slug}
           TransitionProps={{ unmountOnExit: true }}
           style={{ backgroundColor: '#f5f3f1', inlineSize: 'inherit', cursor: 'null' }}
         >
           <AccordionSummary
             expandIcon={(
               <ExpandMoreIcon
                 color={hasArrowToBeRed(q.answers) ? 'error' : 'primary'}
               />
             )}
             answers={isASavedAnswer(q)}
           >
             <Grid
               item
               container
               justifyContent="space-between"
               xs={12}
               xl={10}
               style={{ display: 'flex', alignItems: 'center' }}
             >
               <Grid item xs={11}>
                 <Typography
                   variant="body1"
                 >
                   {q.text}
                 </Typography>
               </Grid>
               <Grid item>
                 { verificatedQuestions && (
                 <Checkbox
                   disableRipple
                   style={{ cursor: 'default' }}
                   checked={verificatedQuestions.includes(q.id)}
                   icon={<RadioButtonUncheckedRoundedIcon style={{ color: 'lightgray' }} />}
                   checkedIcon={<CheckCircleOutlineRoundedIcon style={{ color: 'green' }} />}
                 />
                 )}
               </Grid>
             </Grid>

           </AccordionSummary>
           <AccordionDetails style={{ flexDirection: 'column' }}>
             {
                q.answers.map((answer) => (
                  showAnswer(answer)
                  && (
                  <>
                    {answers
                        && answers
                          .find((a) => a.id === answer.id || a.answer === answer.id) && (
                          <Evidence
                            // eslint-disable-next-line react/jsx-boolean-value
                            isReviewSection={true}
                            answerId={answer.text}
                            answerUrl={answers.find(
                              (a) => a.id === answer.id || a.answer === answer.id,
                            ).url}
                            answerComment={answers.find(
                              (a) => a.id === answer.id || a.answer === answer.id,
                            ).comment}
                          />
                    )}
                  </>
                  )
                ))
                }
           </AccordionDetails>
         </Accordion>
         )
       ))
    }
    </>
  );
}

AccordionContent.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  slugDependenciesQuestion: PropTypes.func.isRequired,
  slugDependenciesAnswer: PropTypes.func.isRequired,
  value: PropTypes.shape({}).isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  verificatedQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isVerified: PropTypes.func.isRequired,
};

AccordionSummary.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  slugDependenciesQuestion: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  slugDependenciesAnswer: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  verificatedQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default AccordionContent;
