/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components material ui
import { Grid, Typography } from '@material-ui/core';

// styles
import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import Link from '../../../../components/common/link/Link';
import LinkExternal from '../../../../components/common/link/LinkExternal';

function InfoColumn({ index, brand, scraperData }) {
  const { instagram, scraperJobId } = brand;
  const classes = useStyles();

  return index > 0 && index < 5 ? (
    <Grid
      container
      className={classes.root}
      wrap="nowrap"
    >
      {scraperData && scraperJobId && (
        <Grid container item>
          <Grid item>
            <Typography variant="h6" style={{ paddingBottom: '2rem' }}>Scraper Results</Typography>
            { scraperData.map(({ term, frequency }) => (
              <Grid container>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.textContainer}
                >
                  <Link
                    to={{
                      pathname: `/scraper-tool/brand/${scraperJobId}`,
                      state: {
                        term,
                        index,
                        previousLocation: useLocation().pathname,
                      },
                    }}
                    text={` ${frequency} - ${term}`}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid
      container
      className={classes.root}
    >
      <Grid item style={{ paddingRight: '2rem' }}>
        <Typography variant="h6" style={{ paddingBottom: '2rem' }}>Supporting Info</Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.textContainer}
          >
            <LinkExternal href={instagram.url} text="Instagram" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6" style={{ paddingBottom: '2rem', opacity: 0 }}>Total</Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.textContainer}
          >
            { instagram.url
              ? (<Typography variant="body1">{Intl.NumberFormat().format(instagram.followers)}</Typography>)
              : (<Typography variant="body1">Not found</Typography>)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

InfoColumn.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  brand: PropTypes.shape({
    instagram: PropTypes.shape({
      url: PropTypes.string.isRequired,
      followers: PropTypes.number.isRequired,
    }).isRequired,
    scraperJobId: PropTypes.string.isRequired,
  }).isRequired,
  scraperData: PropTypes.arrayOf(PropTypes.shape({
    term: PropTypes.string.isRequired,
  })).isRequired,
};
export default InfoColumn;
