/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import {
  Grid, Drawer, Typography,
} from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import classNames from 'classnames';

// local components
import { useUserDispatch, signOut } from '../../context/UserContext';
import Logo from '../../assets/media/logo2.svg';

// styles
import useStyles from './styles';

import menuItems from './config/menuItems';

const getTitle = (string) => {
  const [, stringWithoutSlash] = string.split('/');
  if (stringWithoutSlash.includes('-')) {
    const stringsWithoutdash = stringWithoutSlash.split('-');
    let stringResult = '';
    // eslint-disable-next-line no-return-assign
    stringsWithoutdash.map((s) => stringResult += ` ${s.toUpperCase()}`);
    return stringResult.trim();
  }
  return (stringWithoutSlash.toUpperCase());
};

export default function Sidebar(props) {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const location = useLocation();
  const history = { props };
  return (
    <Drawer
      anchor="top"
      variant="permanent"
    >
      <Grid container className={classes.container}>
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item container xs={3} alignItems="center">
            <Grid item className={classes.logo}>
              <Link to="/risk-tool">
                <img src={Logo} alt="GOY logo" />
              </Link>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                {getTitle(location.pathname)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={9} justifyContent="flex-end">
            {menuItems.map((item) => (
              <Grid
                item
                style={{ minWidth: 100, justifyContent: 'center' }}
                xs={1}
                container
                key={item.id}
                className={
                  classNames(
                    classes.link,
                    location.pathname.includes(`/${item.link}`) && classes.linkActive,
                  )
                }
              >
                <Grid
                  item
                  className={classes.icon}
                >
                  <NavLink to={`/${item.link}`} className={classes.pageLink}>
                    {item.name}
                  </NavLink>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={1} align="right">
              <ExitToAppOutlinedIcon style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => signOut(userDispatch, history)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>

  );
}
