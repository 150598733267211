const settings = (icon) => ({
  columns: [
    {
      name: 'id',
      label: 'ID',
      options: {
        searchable: false,
        filter: false,
        display: 'excluded',
      },
    },
    'Brand Name',
    'Brand Size',
    'Status',
    'Date',
  ],
  options: {
    download: false,
    customToolbar: () => icon,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    selectToolbarPlacement: 'none',
    searchOpen: 'true',
    searchPlaceholder: 'Search . . .',
    sortOrder: { name: 'date', direction: 'desc' },

  },
});

export default settings;
