import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  textContainer: {
    padding: '.75rem 0',
  },
  textContainerFrequency: {
    padding: '.75rem 0',
    textAlign: 'center',
  },
}));
