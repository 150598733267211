/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// components material ui
import {
  Grid, Typography, IconButton, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
// local components
import { JobsAPIsSingleton as APIJobs } from '../../modules/api';
import {
  useUserDispatch,
  signOut,
  useUserState,
} from '../../context/UserContext';

import Card from '../../components/card/Card';
import Switch from '../../components/common/switch/Switch';
import TableTerms from './tables/TableTerms';
import TableSummary from './tables/TableSummary';
import CommentForm from '../../components/comments/CommentForm';
import Comment from '../../components/comments/Comment';
import Loader from '../../components/common/loader/Loader';
import TimelineHistory from './timeline/Timeline';
// styles
import useStyles from './styles';
import GroupSelectVerdict from '../../components/groupButtonVerdict/GroupButtonVerdict';

function Brand({ match, history }) {
  const classes = useStyles();
  const { accessToken } = useUserState();
  const { params } = match;
  const { location: { state } } = history;
  const userDispatch = useUserDispatch();

  // local state
  const [job, setJob] = useState();
  const [update, setUpdate] = useState(false);
  const [comments, setComments] = useState([]);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [isSummary, setIsSummary] = useState(state ? !state.term : true);
  const [isHistory, setIsHistory] = useState(false);
  const [searchValue, setSearchValue] = useState(state ? state.term : '');

  // API call
  useEffect(() => {
    APIJobs.getSpecificJob(accessToken, params.id)
      .then((r) => {
        const { data } = r;
        setJob(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
  }, [update]);
  if (!job) return <Loader />;

  const {
    brand,
    pageCount,
    data,
    scraperDecision,
    createdAt,
    scraperVerdict,
    expertVerdict,
    _id,
    approval,
    verdictHistory,
    approvalHistory,
  } = job;

  const verdictsHistory = approvalHistory && approvalHistory.length > 0
    ? [...verdictHistory, ...approvalHistory.map((entry) => ({ ...entry, approval: true }))]
    : verdictHistory;

  const sortedHistory = verdictHistory ? verdictsHistory.sort(
    (a, b) => Date.now(b.date) - Date.now(a.date),
  ) : null;

  const updateJob = () => setUpdate(!update);

  const handleOpenCommentForm = (id) => {
    setSelectedTerm(id);
    setIsCommentFormOpen(true);
  };

  const handleSelectSingleTerm = (term) => {
    setSearchValue(term);
    setIsSummary(false);
    setIsCommentFormOpen(false);
  };

  const handleSwitch = () => {
    setIsSummary(!isSummary);
    setIsCommentFormOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid item container xs={12} justifyContent="space-between">
        {/* cards */}
        <Grid
          item
          container
          xs={approval ? 12 : 8}
          justifyContent="space-between"
          className={classes.cardsContainer}
        >
          <Grid item xs={2} className={classes.containerCard}>
            <Card pageCount={pageCount} title="Pages Processed" />
          </Grid>
          <Grid item xs={approval ? 2 : 3} className={classes.containerCard}>
            <Card
              title="Scraper Verdict"
              verdict={scraperVerdict}
              date={createdAt}
            />
          </Grid>
          <Grid item xs={approval ? 2 : 3} className={classes.containerCard}>
            <Card
              title="Expert Verdict"
              expert={expertVerdict ? expertVerdict.user.username : ''}
              date={expertVerdict ? expertVerdict.date : ''}
              verdict={expertVerdict ? expertVerdict.outcome : ''}
              approval={approval}
            />
          </Grid>
        </Grid>
        { !approval && (
        <Grid
          item
          xs={3}
          className={classes.containerCard}
          style={
            {
              boxShadow: '0px 6px 12px rgba(0,0,0,0.11)',
              padding: '1rem',
            }
          }
        >
          <GroupSelectVerdict
            updateJob={updateJob}
            jobId={_id}
            scraperVerdict={scraperVerdict}
            expertVerdict={expertVerdict}
          />
        </Grid>
        )}
      </Grid>
      <Typography
        onClick={
          state
            ? () => history.push(
              {
                pathname: `${state && state.previousLocation}`,
                state: state && { index: state.index },
              },
            )
            : () => history.goBack()
      }
      >
        { '< Go back' }
      </Typography>
      <Grid
        item
        container
        xs={12}
        lg={12}
        alignItems="center"
        justifyContent="space-between"
        className={classes.switchContainer}
      >
        <Grid item container justifyContent="space-between" alignItems="center" xs={4}>
          <Typography variant="h4">
            {brand }
          </Typography>
        </Grid>
        {/* switch */}
        <Grid item xs={5} align="right">
          <Typography variant="caption">
            {isSummary ? 'Show full results' : 'Show list of terms'}
          </Typography>
          <Switch color="primary" toogle={handleSwitch} />
        </Grid>
      </Grid>
      {sortedHistory
      && (
      <Grid container alignItems="center">
        <Grid item xs={12} align="right">
          <IconButton onClick={() => setIsHistory(!isHistory)}>
            {isHistory
              ? <HighlightOffRoundedIcon />
              : <AccessAlarmsRoundedIcon />}
          </IconButton>
        </Grid>
      </Grid>
      )}
      {/* table */}
      {isHistory ? (
        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={8}>
            <TimelineHistory historyVerdicts={sortedHistory} />
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12} justifyContent="space-between" style={{ alignSelf: 'flex-start' }}>
          <Grid
            item
            sm={isCommentFormOpen ? 9 : 12}
          >
            {isSummary ? (
              <TableSummary
                data={data}
                handleSelectSingleTerm={handleSelectSingleTerm}
              />
            ) : (
              <TableTerms
                data={data}
                type={scraperDecision}
                jobId={params.id}
                handleOpenCommentForm={handleOpenCommentForm}
                getComments={setComments}
                setIsCommentFormOpen={setIsCommentFormOpen}
                searchValue={searchValue}
              />
            )}
          </Grid>
          <Grid item container xs={2} lg={3}>
            <Grid item>
              {isCommentFormOpen && (
              <CommentForm
                termId={selectedTerm}
                closeForm={setIsCommentFormOpen}
                updateJob={updateJob}
              />
              )}
              {comments && comments.length > 0 && (
              <>
                <Grid item xs={12} align="right">
                  <IconButton
                    className={classes.closeComments}
                    onClick={() => setComments([])}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                {comments.map((comment) => (
                  <Comment
                    comment={comment}
                    updateJob={updateJob}
                    updateComments={setComments}
                  />
                ))}
              </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

    </Grid>
  );
}

Brand.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape({
        term: PropTypes.string,
        index: PropTypes.number,
        previousLocation: PropTypes.string,
      }),
    }),
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
};
export default withRouter(Brand);
