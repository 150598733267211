import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components material ui
import {
  Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button, TextField,
} from '@material-ui/core';
import {
  Person as AccountIcon,
} from '@material-ui/icons';
// local compoents
import { CommentsAPIsSingleton as APIComments } from '../../modules/api';
import { useUserState } from '../../context/UserContext';
// styles
import useStyles from './styles';

export default function CommentForm({
  termId, closeForm, updateJob,
}) {
  const { accessToken, user } = useUserState();
  const classes = useStyles();
  // local
  const [type, setType] = useState('');
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const comment = { type, text };

  // apicall
  const handleAddComment = (term, commentObj) => () => {
    if (!termId || !commentObj.type || !commentObj.text) return;
    const row = termId;
    const data = {
      row,
      type: commentObj.type,
      text: commentObj.text,
    };
    APIComments.postCommentJobs(accessToken, data)
      .then(() => {
        setIsLoading(false);
        updateJob();
        closeForm(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e && e.message && e.message === 'LOGOUT') {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
  };
  //  handles
  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <Grid
      container
      className={classes.containerForm}
    >
      <Grid item container className={classes.containerAuthor}>
        <AccountIcon />
        <Typography variant="body2">{user.username}</Typography>
      </Grid>
      <Grid item container>
        <FormControl variant="outlined" className={classes.formControlComment}>
          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={type}
            onChange={handleChange}
            label="Type"
            fullwidth="true"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="not-info">Term not info in this context</MenuItem>
            <MenuItem value="not-reported">Scraper didn&apos;t report a relevant term</MenuItem>
            <MenuItem value="new-term">New term - Synonym</MenuItem>
            <MenuItem value="unrelated-context">Term relates to a different entity</MenuItem>
          </Select>
          <TextField
            id="type"
            value={text}
            onChange={(e) => setText(e.target.value)}
            margin="normal"
            type="text"
            fullwidth="true"
            variant="outlined"
            label="Comment"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between" className={classes.buttonsComment}>
        <Grid item md={12} lg={5}>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            className={classes.buttonConfirm}
            onClick={handleAddComment(termId, comment)}
            disabled={isLoading}
          >
            Comment
          </Button>
        </Grid>
        <Grid item md={12} lg={5}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            className={classes.buttonCancel}
            onClick={() => closeForm(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

CommentForm.propTypes = {
  termId: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
};
