import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';
import backgroundImg from '../../../assets/media/etikocollabfeature.jpg';
// logo

// context
import { useUserDispatch, loginUser } from '../../../context/UserContext';

// error boundaries
const schema = yup.object().shape({
  loginValue: yup.string().email('Please include a valid email').required(),
  passwordValue: yup.string().required('Please include a valid password'),
});
function Login(props) {
  const classes = useStyles();
  const history = { props };

  // global
  const userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please include a valid email or password');
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    await schema
      .validate({
        loginValue,
        passwordValue,
      }).then(() => {
        loginUser(
          userDispatch,
          loginValue,
          passwordValue,
          history,
          setIsLoading,
          setError,
        );
      }).catch((e) => {
        setPasswordValue('');
        setLoginValue('');
        setError(true);
        setErrorMessage(e.message);
      });
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ backgroundImage: `linear-gradient(#00000091, #00000075), url(${backgroundImg})` }}
    >
      <div className={classes.formContainerLogin}>
        <Grid direction="column" container justifyContent="center" item xs={6} className={classes.form}>
          <>
            <Typography className={classes.greeting}> Scraper Tool Dashboard</Typography>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  error: classes.textFieldUnderline,
                },
                endAdornment: <AccountCircle />,
              }}
              value={loginValue}
              onChange={(e) => {
                setError(false);
                setLoginValue(e.target.value);
              }}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
              error={error}
              variant="outlined"
              label="Email"
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  error: classes.textFieldUnderline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="outlined"
              label="Password"
              error={error}
              onKeyDown={(e) => handleKeyPress(e)}
            />
            <Grid item>
              {/* <Typography
                color="secondary"
                variant="caption"
                className={classes.forgetButton}
                noWrap
                component={Link}
                to="/forgot-password"
              >
                Forgot Password ?
              </Typography> */}
            </Grid>
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  // disabled={
                  //     loginValue.length === 0 || passwordValue.length === 0
                  //   }
                  onClick={() => handleLogin()}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{ height: 56, width: '100%' }}
                >
                  Login
                </Button>
              )}
            </div>
            <Fade in={error}>
              <Typography color="primary" className={classes.errorMessage}>
                {errorMessage}
              </Typography>
            </Fade>
          </>
        </Grid>
      </div>
    </Grid>
  );
}
Login.propTypes = {
  history: PropTypes.shape({}).isRequired,
};
export default withRouter(Login);
