import React from 'react';
import { Link as LinkRouterDom } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI components
import { Typography } from '@material-ui/core';

// styles
import useStyles from './styles';

const Link = ({ to, text }) => {
  const classes = useStyles();

  return (
    <LinkRouterDom to={to} className={classes.link}>
      <Typography textColor="primary" variant="body1" style={{ textTransform: 'capitalize' }} className={classes.text}>
        {text}
      </Typography>
    </LinkRouterDom>
  );
};
Link.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
export default Link;
