import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip as MUITooltip } from '@material-ui/core';

export default function Tooltip({ children, text }) {
  if (!children) return <></>;
  return (
    <MUITooltip
      disableFocusListener
      disableTouchListener
      title={text}
      placement="top"
      arrow
    >
      {children}
    </MUITooltip>
  );
}
Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
