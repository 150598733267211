import ApiRiskTool from './ApiRiskTool';

export default class RiskToolAPIs extends ApiRiskTool {
  getAllBrands(accessToken) {
    return this.instance.get('risk-assessment/brands', this.constructor.addAuthorizationHeader(accessToken));
  }

  getBrand(accessToken, id) {
    return this.instance.get(`risk-assessment/brands/${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  deleteBrand(accessToken, id) {
    return this.instance.delete(`risk-assessment/brands/${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  setBrandSize(accessToken, id, criteria) {
    const data = { brand: id, criteria };
    return this.instance.post('risk-assessment/brand-size', data, this.constructor.addAuthorizationHeader(accessToken));
  }

  getBrandSize(accessToken, id) {
    return this.instance.get(`risk-assessment/brand-size?brand=${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  patchBrandUrl(accessToken, id, data) {
    return this.instance.patch(`risk-assessment/brands/${id}`, data, this.constructor.addAuthorizationHeader(accessToken));
  }

  getQuestionnaire(accessToken, size) {
    return this.instance.get(`risk-assessment/questionnaire?size=${size}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  postAnswers(accessToken, data) {
    return this.instance.post('risk-assessment/brands-answers', data, this.constructor.addAuthorizationHeader(accessToken));
  }

  getRiskIndicators(accessToken, id) {
    return this.instance.get(`risk-assessment/indicators?brand=${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }
}
