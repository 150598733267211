/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// components material ui
import { Grid } from '@material-ui/core';
import Loader from '../../../../../components/common/loader/Loader';

// local components
import AccordionContent from '../AccordionContent';
// styles
import useStyles from './styles';

function TabContentQuestions({
  questions, answers, setAnswers, beAnswers, isQuestionAnswered, setUpdated, updated,
  answersWithError, handleVerificationQuestions, handleOutDatedAnswer,
  verificatedQuestions, riskStatus, dependencies, isVerified, expanded, setExpanded,

}) {
  const classes = useStyles();

  useEffect(() => {
    if (!questions) {
      setUpdated(!updated);
    }
    return null;
  }, []);
  //  handlers
  if (!questions || !beAnswers) return <Loader />;

  const slugDependenciesQuestion = answers.map((answer) => answer.slug);
  const slugDependenciesAnswer = answers.map((answer) => answer.slug);

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
    >
      <AccordionContent
        questions={questions}
        answers={answers}
        setAnswers={setAnswers}
        beAnswers={beAnswers}
        isQuestionAnswered={isQuestionAnswered}
        slugDependenciesQuestion={slugDependenciesQuestion}
        slugDependenciesAnswer={slugDependenciesAnswer}
        answersWithError={answersWithError}
        verificatedQuestions={verificatedQuestions}
        handleVerificationQuestions={handleVerificationQuestions}
        riskStatus={riskStatus}
        dependencies={dependencies}
        isVerified={isVerified}
        expanded={expanded}
        setExpanded={setExpanded}
        handleOutDatedAnswer={handleOutDatedAnswer}
      />
    </Grid>
  );
}

TabContentQuestions.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isQuestionAnswered: PropTypes.func.isRequired,
  setUpdated: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  verificatedQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleVerificationQuestions: PropTypes.func.isRequired,
  riskStatus: PropTypes.string.isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVerified: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  handleOutDatedAnswer: PropTypes.func.isRequired,
};
export default TabContentQuestions;
