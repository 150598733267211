import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    borderRadius: 10,
  },
  title: {
    padding: '.5rem 0',
    textTransform: 'uppercase',
  },
  verdictBox: {
    height: '2rem',
    marginBottom: '1rem',
  },
  verdict: {
    textTransform: 'uppercase',
  },
  'verdict-info': {
    color: theme.palette.success.dark,
  },
  'verdictBox-info': {
    backgroundColor: theme.palette.success.light,
    border: '1px solid #0a735014',
    borderRadius: '0 0 5px 5px',
  },
  'verdict-no-info': {
    color: theme.palette.error.dark,
  },
  'verdictBox-no-info': {
    backgroundColor: theme.palette.error.light,
    border: '1px solid #730a1e14',
    borderRadius: '0 0 5px 5px',
  },
  'verdict-probably-info': {
    color: theme.palette.success.dark,
  },
  'verdictBox-probably-info': {
    backgroundColor: theme.palette.warning.light,
    border: '1px solid #730a1e14',
    borderRadius: '0 0 5px 5px',
  },
  approval: {
    color: theme.palette.success.main,
  },
}));
