// material UI
import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
/* eslint-disable no-underscore-dangle */
import React from 'react';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import SyncIcon from '@material-ui/icons/Sync';
// local components
import TuneIcon from '@material-ui/icons/Tune';
import moment from 'moment';
import Tooltip from '../../../components/common/tooltip/Tooltip';
import Link from '../../../components/common/link/Link';
import Chip from '../../../components/common/chip/Chip';
import settings from './utils/tableSettings';
// styles
import useStyles from './styles';

const components = {
  icons: {
    FilterIcon: TuneIcon,
  },
};

export default function TableTerms({
  removeThisJob,
  update,
  data,
}) {
  const classes = useStyles();
  const filteredStatus = (status) => {
    switch (status) {
      case 'completed':
        return {
          icon: <CheckCircleOutlineRoundedIcon className={classes.checkIcon} />,
          text: 'Completed',
          tooltip: 'Completed: The scraping process is completed, you can now look at the results',
        };
      case 'error':
        return {
          icon: <BlockIcon className={classes.blockIcon} />,
          text: 'Interrupted',
          tooltip: `Interrupted: Something happened before the process could have been completed. 
            The process has been stopped`,
        };
      case 'running':
        return {
          icon: <RepeatRoundedIcon className={classes.running} />,
          text: 'Running',
          tooltip: 'Running: Scraping the brand\'s pages',
        };
      case 'queued':
        return {
          icon: <QueueRoundedIcon className={classes.queue} />,
          text: 'Queued',
          tooltip: 'Queued: before starting the scraping process is waiting for other processes to terminate',
        };
      default:
        return '';
    }
  };

  const columns = React.useMemo(() => [
    {
      name: '_id',
      label: 'ID',
      options: {
        searchable: false,
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'brand',
      label: 'Name',
      options: {
        filter: false,
        searchable: true,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          const id = rowData[0];
          const status = rowData[2];
          return (
            status === 'completed' ? (
              <Link
                to={`/scraper-tool/brand/${id}`}
                text={value}
                style={{ textTransform: 'uppercase' }}
              />
            ) : <Typography variant="caption">{value}</Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Scraping Status',
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value) => (
          <Grid item align="center">
            <Tooltip text={filteredStatus(value).tooltip || ''}>
              {filteredStatus(value).icon}
            </Tooltip>
          </Grid>
        ),
      },
    },
    {
      name: 'scraperVerdict',
      label: 'Scraper Verdict',
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value) => (
          <Grid item align="center">
            <Chip
              label={value}
            />
          </Grid>
        ),
      },
    },
    {
      name: 'expertVerdict',
      label: 'Expert Verdict',
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value) => (
          <Grid item align="center">
            <Chip
              label={value?.outcome || 'No verdict'}
            />
          </Grid>
        ),
      },
    },
    {
      name: 'createdAt',
      label: 'Date',
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          const status = rowData[2];
          return (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="body1">{moment(value).format('DD MMM YYYY')}</Typography>
              </Grid>
              <Grid item xs={4} align="right">
                {status === 'error' && (
                  <Tooltip text="Delete this result">
                    <DeleteOutlineRoundedIcon
                      className={classes.deleteBnt}
                      onClick={() => removeThisJob(rowData[0])}
                    />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        },
      },
    },
  ],
  []);

  const { options } = React.useMemo(() => settings(
    <Tooltip text="Refresh results">
      <IconButton onClick={() => update}><SyncIcon /></IconButton>
    </Tooltip>,
  ));

  return (
    <MUIDataTable
      className={classes}
      data={data}
      {...components}
      columns={columns}
      options={options}
    />
  );
}
TableTerms.propTypes = {
  data: PropTypes.shape([{}]).isRequired,
  update: PropTypes.func.isRequired,
  removeThisJob: PropTypes.func.isRequired,
};
