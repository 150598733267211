import Api from './Api';

export default class CommentsAPIs extends Api {
  postCommentJobs(accessToken, data) {
    return this.instance.post('comments', data, this.constructor.addAuthorizationHeader(accessToken));
  }

  patchComment(accessToken, commentId, data) {
    return this.instance.patch(`comments/${commentId}`, data, this.constructor.addAuthorizationHeader(accessToken));
  }

  deleteComment(accessToken, id) {
    return this.instance.delete(`comments/${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }
}
