export const settingsTerms = {
  columns: [
    {
      name: 'id',
      label: 'ID',
      options: {
        searchable: false,
        filter: false,
        display: 'excluded',
      },
    },
    'Term',
    'Frequency',
    { name: 'url', options: { filter: false } },
    { name: 'context', options: { filter: false } },
    { name: 'Comments', options: { filter: false, search: false } },

  ],
  options: {
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'single',
    selectToolbarPlacement: 'none',
    searchOpen: 'true',
    selectableRowsOnClick: true,
    searchPlaceholder: 'Search . . .',
  },
};

export const settingsSummary = (termComponent) => ({
  columns: [
    {
      name: 'term',
      label: 'Term',
      options: {
        searchable: true,
        filter: true,
        customBodyRender: (value) => (
          termComponent(value)
        ),
      },
    },
    {
      name: 'Frequency',
    },
  ],
  options: {
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'single',
    selectToolbarPlacement: 'none',
    searchOpen: true,
    searchText: 'testosterone',
    selectableRowsOnClick: true,
    searchPlaceholder: 'Search . . .',
    sortOrder: { name: 'Frequency', direction: 'desc' },
  },
});
