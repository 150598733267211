/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// components material ui
import {
  Typography, Checkbox, FormGroup, FormControlLabel, FormControl, Grid, IconButton,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from '../../../../components/common/loader/Loader';
import { useUserState } from '../../../../context/UserContext';
import { useBrandState } from '../../../../context/BrandContext';
// components local
import Evidence from './main/Evidence';
// styles
import useStyles from './styles';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = ({
  answers, handleChange, questionId, ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <MuiAccordionSummary
        style={{ backgroundColor: '#f5f3f1' }}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
        className={classes.accordionSummary}
      />
      {answers.length > 0 && answers.map((answer) => (
        <Grid
          container
          item
          xs={12}
          xl={10}
          alignItems="center"
          className={answer.status === 'Outdated' ? classes.answerContainerOutdated : classes.answerContainer}
          justifyContent="space-between"
          onClick={() => handleChange(questionId)}
        >
          <Typography variant="body1" className={classes.answerText}>
            -
            {' '}
            {answer.text}
          </Typography>
          <Grid item xs={12} style={{ paddingTop: '.5rem' }} align="right">
            <IconButton disabled size="small">
              <PersonRoundedIcon />
            </IconButton>
            <Typography variant="caption" className={classes.details}>
              {answer.user && answer.user.username}
            </Typography>
            <IconButton disabled size="small">
              <TodayRoundedIcon />
            </IconButton>
            <Typography variant="caption" className={classes.details}>
              {moment(answer.date).format('DD MMM YYYY')}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
}));

function AccordionContent({
  questions, answers,
  setAnswers, beAnswers, slugDependenciesQuestion,
  slugDependenciesAnswer, answersWithError,
  // eslint-disable-next-line no-unused-vars
  verificatedQuestions, handleVerificationQuestions, riskStatus, dependencies, isVerified,
  expanded,
  setExpanded,
  handleOutDatedAnswer,
}) {
  const childrenAnswerDependendecies = () => {
    // this function is used to have a lookup collections of the parent dependencies
    // then it is set up with a lazy initialization in order to avoid repetitions of heavy logic
    const dep = {};
    questions.map((q) => q.answers.map((a) => {
      if (a.dependency !== null) {
        dep[a.dependency] = dep[a.dependency] ? [...dep[a.dependency], a] : [a];
        return dep[a.dependency];
      }
      return null;
    }));
    return dep;
  };

  // context
  const { user } = useUserState();
  const { verifiedAnswers } = useBrandState();
  // local

  const [dependentChildrenAnswers] = useState(() => childrenAnswerDependendecies());

  const questionHasAnswers = (question) => {
    const questionsWithAnswers = [];
    if (answers.length > 0) {
      answers.map((a) => {
        if (question.answers.filter((ans) => ans.id === a.id).length === 0) {
          return false;
        }
        return questionsWithAnswers.push(question.id);
      });
    }
    return questionsWithAnswers;
  };

  const savedAnswers = beAnswers.map((a) => a.id);
  const hasAnswerBeenSelected = (answerId) => {
    if (answers.length > 0) {
      const hasBeenSelected = answers.some(
        (a) => (a.id === answerId),
      );
      if (hasBeenSelected) {
        return true;
      }
    }
    return false;
  };
  // handlers

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //  on select
  const handleAnswers = (e, answerSelected) => {
    const { checked } = e.target;
    const dependentChildren = dependentChildrenAnswers[answerSelected.slug];
    // Level answers
    if (dependentChildren) {
      const answerIDs = [...dependentChildren.map((a) => a.id), answerSelected.id];
      if (checked) {
        // first check if the answers are present already
        const someArePresent = answers.some((a) => answerIDs.includes(a.id));
        if (someArePresent) {
          // if so, remove them, then add them
          setAnswers(answers.filter((item) => !answerIDs.includes(item.id)));
          setAnswers([...answers, {
            id: answerSelected.id, url: '', comment: '', slug: answerSelected.slug, isNew: true, user, date: moment(),
          }, ...dependentChildren.map((dc) => ({
            id: dc.id, url: 'auto', comment: 'auto', slug: dc.slug, isNew: true, user, date: moment(),
          }))]);
        }
        // if not, add them
        if (!someArePresent) {
          setAnswers([...answers, {
            id: answerSelected.id, url: '', comment: '', slug: answerSelected.slug, isNew: true, user, date: moment(),
          }, ...dependentChildren.map((dc) => ({
            id: dc.id, url: 'none', comment: 'Based on previous answers', slug: dc.slug, isNew: true, user, date: moment(),
          }))]);
        }
        // if unselected
      } else {
        // remove answerIDs
        const deselectedHasDependency = dependencies
          .filter(({ parent }) => parent.includes(answerSelected.id));
        if (deselectedHasDependency.length > 0) {
          // check if there are nested dependencies
          const parentsId = [];
          const sub = [];
          deselectedHasDependency.map(({ child }) => parentsId.push(child));
          dependencies.map((dep) => parentsId.length > 0 && parentsId.map((id) => {
            if (dep.parent.includes(id)) {
              return sub.push(dep);
            }
            return null;
          }));
          if (sub.length > 0) {
            setAnswers(() => answers
              // remove the deselected answer's dependencies'
              .filter((item) => deselectedHasDependency.every(({ child }) => child !== item.id))
              // remove the deselected answer's dependencies' dependencies
              .filter((item) => sub.every(({ child }) => child !== item.id))
              // remove the deselected answer
              .filter((item) => item.id !== answerSelected.id));
          } else {
            setAnswers(() => answers
              // remove the deselected answer's dependencies
              .filter((item) => deselectedHasDependency.every(({ child }) => child !== item.id))
              // remove the deselected answer
              .filter((item) => item.id !== answerSelected.id));
          }
        } else {
          setAnswers(answers.filter((item) => !answerIDs.includes(item.id)));
        }
      }
    }
    // Level questions
    if (!dependentChildren) {
      if (checked) {
        // first check if the answer is present already
        if (answers.find((a) => a.id === answerSelected.id)) {
          // if so, remove it, then add it with new values
          setAnswers(answers.filter((item) => item.id !== answerSelected.id));
          setAnswers([...answers, {
            id: answerSelected.id, url: '', comment: '', slug: answerSelected.slug, isNew: true, user, date: moment(),
          }]);
        }
        const hasBeenSaved = savedAnswers.includes(answerSelected.id);
        if (hasBeenSaved) {
          // if it was a saved answer, restore it together with its dependencies
          const savedSelectedAnswers = dependencies
            .filter(({ parent }) => parent.includes(answerSelected.id));
          const savedSelectedAnswersId = [];
          const sub = [];
          savedSelectedAnswers.map(({ child }) => savedSelectedAnswersId.push(child));
          dependencies
            .map((dep) => savedSelectedAnswersId.length > 0
          && savedSelectedAnswersId.map((id) => {
            if (dep.parent.includes(id)) {
              return sub.push(dep);
            }
            return null;
          }));

          if (savedSelectedAnswersId.length === 0) {
            // if so, remove it, then add it with new values
            setAnswers(answers.filter((item) => item.id !== answerSelected.id));
            setAnswers([
              ...answers,
              {
                id: answerSelected.id, url: '', comment: '', slug: answerSelected.slug, isNew: true, user, date: moment(),
              }]);
          }
          if (savedSelectedAnswersId.length > 0) {
            if (sub.length > 0) {
              setAnswers(() => [
                ...answers.filter((a) => a.isNew),
                ...beAnswers.filter((item) => sub.filter(({ child }) => child === item.id))
                  .filter((item) => savedSelectedAnswers.filter(({ child }) => child === item.id)),
              ]);
            } else {
              setAnswers(() => [
                ...answers.filter((a) => a.isNew),
                ...beAnswers.filter((item) => savedSelectedAnswers
                  .filter(({ child }) => child === item.id)),
              ]);
            }
          }
        } else {
          // if not present just add to answers
          setAnswers([...answers, {
            id: answerSelected.id, url: '', comment: '', slug: answerSelected.slug, isNew: true, user, date: moment(),
          }]);
        }
      } else {
        // need to check if the deselected answer has dependency
        // and remove them from the answers array

        const deselectedHasDependency = dependencies
          .filter(({ parent }) => parent.includes(answerSelected.id));

        if (deselectedHasDependency.length > 0) {
          // check if there are nested dependencies
          const parentsId = [];
          const sub = [];
          deselectedHasDependency.map(({ child }) => parentsId.push(child));
          dependencies.map((dep) => parentsId.length > 0 && parentsId.map((id) => {
            if (dep.parent.includes(id)) {
              return sub.push(dep);
            }
            return null;
          }));
          if (sub.length > 0) {
            setAnswers(() => answers
            // remove the deselected answer's dependencies'
              .filter((item) => deselectedHasDependency.every(({ child }) => child !== item.id))
            // remove the deselected answer's dependencies' dependencies
              .filter((item) => sub.every(({ child }) => child !== item.id))
            // remove the deselected answer
              .filter((item) => item.id !== answerSelected.id));
          } else {
            setAnswers(() => answers
            // remove the deselected answer's dependencies
              .filter((item) => deselectedHasDependency.every(({ child }) => child !== item.id))
            // remove the deselected answer
              .filter((item) => item.id !== answerSelected.id));
          }
        } else {
          setAnswers(answers.filter((item) => item.id !== answerSelected.id));
        }
      }
    }
  };

  const hasErrors = (a) => {
    if (answersWithError && answersWithError.length > 0) {
      return answersWithError.some((id) => id === a);
    }
    return false;
  };
  const handleEvidenceUrl = (e, answerId, comment, slug) => {
    const { value } = e.target;
    const newAnswer = answers.filter((a) => a.id === answerId)[0];
    newAnswer.answerId = answerId;
    newAnswer.url = value;
    newAnswer.comment = comment;
    newAnswer.slug = slug;
    newAnswer.isNew = true;
    newAnswer.user = user;
    newAnswer.date = moment();
    setAnswers(
      //! review this
      // filter method to avoids repetitions
      [...answers.filter((item) => item.id !== answerId),
        newAnswer,
      ],
    );
  };

  const handleEvidenceComment = (e, answerId, url, slug) => {
    const { value } = e.target;
    const newAnswer = answers.filter((a) => a.id === answerId)[0];
    newAnswer.answerId = answerId;
    newAnswer.url = url;
    newAnswer.comment = value;
    newAnswer.slug = slug;
    newAnswer.isNew = true;
    newAnswer.user = user;
    newAnswer.date = moment();

    setAnswers(
      // filter method to avoids repetitions
      [...answers.filter((item) => item.id !== answerId),
        newAnswer,
      ],
    );
  };

  if (!questions || !beAnswers) return <Loader />;

  const showQuestion = (question) => {
    if (question.dependencies) {
      return question.dependencies.some((dep) => slugDependenciesQuestion.includes(dep));
    }
    return true;
  };

  const showAnswer = (answer) => {
    if (answer.dependency) {
      return slugDependenciesAnswer.includes(answer.dependency);
    }
    return true;
  };

  const hasArrowToBeRed = (questionAnswers) => {
    if (answersWithError.length > 0 && answersWithError
      .some((a) => questionAnswers.filter((qa) => qa.id === a).length > 0)) {
      return true;
    }
    return false;
  };

  //! review this
  const isASavedAnswer = (question) => {
    const questionsAnswersId = question.answers.map((a) => a.id);
    const compositeArray = [];
    if (answers.length > 0 && answers.some((a) => questionsAnswersId.includes(a.id))) {
      answers.map((a) => {
        if (questionsAnswersId.includes(a.id)) {
          return compositeArray.push({
            ...a,
            text: question.answers.find((ans) => ans.id === a.id).text,
          });
        }
        return compositeArray;
      });
    }
    return compositeArray;
  };

  const hasQuestionOutdatedAnswers = (question) => {
    const questionsAnswersId = question.answers.map((a) => a.id);
    return answers.some((a) => a.status === 'Outdated' && questionsAnswersId.includes(a.id));
  };

  const isOutadatedQuestionChecked = (question) => {
    const questionsAnswersId = question.answers.map((a) => a.id);
    return answers.some((a) => a.status === 'Outdated' && a.isNew && questionsAnswersId.includes(a.id));
  };
  return (
    <>

      {
       beAnswers && answers && questions.map((q) => (
         showQuestion(q) && (
         <Accordion
           expanded={expanded === q.id}
           onChange={handleChange(q.id)}
           key={q.slug}
           TransitionProps={{ unmountOnExit: true }}
           style={{ backgroundColor: '#f5f3f1', inlineSize: 'inherit' }}
         >
           <AccordionSummary
             expandIcon={(
               <ExpandMoreIcon
                 style={{ color: !hasArrowToBeRed(q.answers) && questionHasAnswers(q).includes(q.id) && 'green' }}
                 color={hasArrowToBeRed(q.answers) ? 'error' : 'primary'}
               />
             )}
             answers={isASavedAnswer(q)}
             handleChange={handleChange(q.id)}
           >
             <Grid
               item
               xs={12}
               xl={10}
               style={{ display: 'flex', alignItems: 'center' }}
               container
               justifyContent="space-between"
             >
               <Grid item xs={10}>
                 <Typography
                   variant="body2"
                 >
                   {q.text}
                 </Typography>
               </Grid>
               { verificatedQuestions && (riskStatus === ('In verification')) && (
               <Checkbox
                 disableRipple
                 checked={verifiedAnswers.includes(q.id)}
                 onChange={(e) => handleVerificationQuestions(e, q.id, q.answers.map((a) => a.id))}
                 onClick={(e) => e.stopPropagation()}
                 icon={<RadioButtonUncheckedRoundedIcon style={{ color: 'lightgray' }} />}
                 checkedIcon={<CheckCircleOutlineRoundedIcon style={{ color: 'green' }} />}
               />
               )}
               { verificatedQuestions && hasQuestionOutdatedAnswers(q) && (riskStatus === ('In progress') || riskStatus === 'Not started') && (
               <FormGroup>
                 <FormControlLabel
                   control={(
                     <Checkbox
                       disableRipple
                       checked={isOutadatedQuestionChecked(q)}
                       onChange={(e) => handleOutDatedAnswer(e, q)}
                       onClick={(e) => e.stopPropagation()}
                       icon={<RadioButtonUncheckedRoundedIcon style={{ color: 'lightgray' }} />}
                       checkedIcon={<CheckCircleOutlineRoundedIcon style={{ color: 'green' }} />}
                     />
                )}
                   label="Confirmed"

                 />
               </FormGroup>

               )}
             </Grid>
           </AccordionSummary>
           <AccordionDetails>
             <Grid item container xs={12} xl={10}>
               <FormControl component="fieldset">
                 <FormGroup>
                   {
                   q.answers.map((answer) => (
                     showAnswer(answer) && (
                     <>
                       <FormControlLabel
                         key={answer.id}
                         control={(
                           <Checkbox
                             checked={
                                (answers && hasAnswerBeenSelected(answer.id)) || answer.dependency
                              }
                             onChange={(e) => handleAnswers(e, answer)}
                             name={answer.id}
                             color="primary"
                             disabled={answer.dependency}
                           />
                            )}
                         label={answer.text}
                       />
                       {answers
                         && answers
                           .find((a) => a.id === answer.id) && (
                       // in case it depends from parent answer no need of evidence
                         !answer.dependency && (
                         <Evidence
                           handleEvidenceUrl={handleEvidenceUrl}
                           handleEvidenceComment={handleEvidenceComment}
                           answerId={answer.id}
                           slug={answer.slug}
                           hasErrors={hasErrors}
                           disabled={!!answer.dependency}
                           answersWithError={answersWithError}
                           isURLRequired={answer.isURLRequired}
                           answerUrl={answers.find(
                             (a) => a.id === answer.id,
                           ).url}
                           answerComment={answers.find(
                             (a) => a.id === answer.id,
                           ).comment}
                         />
                         )
                       )}
                     </>
                     )
                   ))
                }
                 </FormGroup>
               </FormControl>
             </Grid>
           </AccordionDetails>
         </Accordion>
         )
       ))
    }
    </>
  );
}

AccordionContent.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  slugDependenciesQuestion: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  slugDependenciesAnswer: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  verificatedQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleVerificationQuestions: PropTypes.func.isRequired,
  riskStatus: PropTypes.string.isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVerified: PropTypes.bool.isRequired,
  expanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
  handleOutDatedAnswer: PropTypes.func.isRequired,
};

AccordionSummary.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  slugDependenciesQuestion: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  slugDependenciesAnswer: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
};
export default AccordionContent;
