import React from 'react';
import PropTypes from 'prop-types';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import NextPlanRoundedIcon from '@mui/icons-material/NextPlanRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { Grid } from '@material-ui/core';

// styles
import useStyles from './styles';
import Tooltip from '../../../components/common/tooltip/Tooltip';
// eslint-disable-next-line react/prop-types
const StatusIconsGroup = ({ statusData }) => {
  const classes = useStyles();
  const { status } = statusData;
  const statusDetails = [

    {
      status: 'In progress',
      icon: <PendingRoundedIcon />,
      tooltip: 'In Progress: The assessment has started, but not completed',
    },
    {
      status: 'Answered',
      icon: <QuestionAnswerRoundedIcon />,
      tooltip: 'Answered: All the questions have been answered',
    },
    {
      status: 'In verification',
      icon: <HourglassEmptyRoundedIcon />,
      tooltip: 'In Verification: Submitted for verification / verification underway',
    },
    {
      status: 'Verification skipped',
      icon: <NextPlanRoundedIcon />,
      tooltip: 'Verification Skipped: decided to skip verification',
    },
    {
      status: 'Verified',
      icon: <PublishedWithChangesRoundedIcon />,
      tooltip: 'Verified: All answers have been verified',
    },
    {
      status: 'Published',
      icon: <VerifiedUserRoundedIcon />,
      tooltip: 'Published: The risk assessment has been published',
    },
  ];

  const getSelectedStatus = (index) => {
    const selectedStatus = statusDetails.indexOf(
      statusDetails.find(({ status: statusName }) => statusName === status),
    );
    const selected = index <= selectedStatus ? 'iconSelected' : 'iconNotSelected';
    return selected;
  };

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.container}>
      {
        statusDetails.map((statusDetail, index) => (
          <Tooltip text={statusDetail.tooltip}>
            <Grid item key={statusDetail} className={classes[`${getSelectedStatus(index)}`]}>
              {statusDetail.icon}
            </Grid>
          </Tooltip>
        ))
    }
    </Grid>
  );
};

StatusIconsGroup.propTypes = {
  statusData: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatusIconsGroup;
