import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import PropTypes from 'prop-types';
// styles
import useStyles from './styles';

const GroupAvatars = ({ comments }) => {
  const classes = useStyles();

  const users = comments.map((comment) => comment.user.username);
  return (
    <AvatarGroup max={3}>
      {users.map((user) => (
        <Avatar
          alt="user avatar"
          classes={classes}
          key={`${user[0]}-${Math.random()}`}
        >
          {user[0].toUpperCase()}
        </Avatar>
      ))}
    </AvatarGroup>

  );
};
GroupAvatars.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default GroupAvatars;
