import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    overflowY: 'auto',
  },
  textContainer: {
    padding: '.75rem 0',
  },
  selected: {
    backgroundColor: '#d4e1de',
    padding: '.25rem .5rem',
    borderRadius: '2px',
  },
  evidenceContainer: {
    padding: '1rem 2rem',
  },
  evidenceContainerReview: {
    padding: '1rem 2rem',
    borderBottom: '1px solid #bfbdbd',
  },
  text: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    padding: '.5rem 1rem',
    borderRadius: '4px',
    inlineSize: 'fit-content',
    minWidth: '15rem',
  },
  indicatorContainer: {
    padding: '1rem .5rem',
    borderBottom: '1px solid #eaeaea',
    minWidth: '50%',
  },
  successString: {
    backgroundColor: '#d4e1de6e',
    color: theme.palette.success.dark,
    padding: '.5rem',
    borderRadius: 5,
    minWidth: 100,
  },
  partiallyString: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    padding: '.5rem',
    borderRadius: 5,
    minWidth: 100,
  },
  negativeString: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    padding: '.5rem',
    borderRadius: 5,
    minWidth: 100,
  },
  gray: {
    color: theme.palette.text.secondary,
    borderRadius: 5,
    minWidth: 100,
  },
}));
