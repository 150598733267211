/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

// material UI
import { Typography, Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import TuneIcon from '@material-ui/icons/Tune';

// local components
import { settingsSummary as settings } from './helpers/tableSettings';
// styles
import useStyles from './styles';

const components = {
  icons: {
    FilterIcon: TuneIcon,
  },
};

export default function TableSummary({
  data, handleSelectSingleTerm,
}) {
  const classes = useStyles();
  const allCol = [];
  const iteration = {};
  const uniqueTerms = [];
  const constructor = [];
  data.forEach((row) => {
    const {
      _id, term, frequency,
    } = row;

    if (!uniqueTerms.includes(term)) {
      constructor.push([term, frequency]);
      uniqueTerms.push(term);
    }
    if (allCol.filter((rowCollected) => rowCollected[0] === row.term)) {
      iteration[`${row.term}`] = iteration[`${row.term}`] ? iteration[`${row.term}`] + 1 : iteration[`${row.term}`] = 1;
    }
    return allCol.push([
      _id,
      term,
      frequency,
    ]);
  });

  // const filteredData = Object.entries(iteration);
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'single',
    selectToolbarPlacement: 'none',
    searchOpen: true,
    searchPlaceholder: 'Search . . .',
    sortOrder: { name: 'Frequency', direction: 'desc' },
    customBodyRender: (value) => (<Grid align="center">{value}</Grid>),

  };

  const termComponent = (value) => (
    <Typography variant="body2" onClick={() => handleSelectSingleTerm(value)} className={classes.urlLink}>
      {value}
    </Typography>
  );
  const { columns } = settings(termComponent);
  return (
    <MUIDataTable
      className={classes}
      data={constructor}
      {...{ components }}
      columns={columns}
      options={options}
    />
  );
}
TableSummary.propTypes = {
  data: PropTypes.shape([]).isRequired,
  handleSelectSingleTerm: PropTypes.func.isRequired,
};
