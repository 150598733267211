import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1rem',
    width: '100%',
    borderRadius: 10,
    border: '1px solid black',

  },

  container: {
    root: {
      borderRadius: 10,
      border: '1px solid black',
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '0',
    boxShadow: 'none',
    borderRadius: 10,
    border: '1px solid black',

  },
  table: {
    width: '100%',
    borderRadius: 10,
    border: '1px solid black',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[50],
    padding: 0,
    border: '1px solid black',
  },
  tableHeadCell: {
    padding: 8,
    fontWeight: 600,
  },
  tableCell: {
    padding: 8,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  tablePaginationP: {
    fontSize: '.75rem',
  },
  brandTitle: {

    color: theme.palette.primary.main,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  blockIcon: {
    color: theme.palette.error.main,
  },
  queue: {
    color: theme.palette.warning.main,
  },
  running: {
    color: theme.palette.primary.main,
  },
  circularProgress: {
    fontSize: 12,
  },
  removeAlertContainer: {
    padding: '0.5rem',
    boxShadow: theme.customShadows.sm,
  },
  textWarning: {
    color: theme.palette.warning.main,
    padding: '0.5rem 0',
  },
  deleteBnt: {
    color: theme.palette.primary.lighter,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
