import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#f5f3f1',
    backgroundSize: 'cover',
  },
  form: {
    display: 'flex',
  },
  formContainer: {
    width: '40%',
    height: '50%',
    display: 'flex',
    borderRadius: 10,
    backgroundColor: '#ffffffe6',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  formContainerLogin: {
    width: '40%',
    height: '50%',
    display: 'flex',
    borderRadius: 10,
    backgroundColor: '#ffffffe6',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    justifyContent: 'center',
  },
  backBTNBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  backBTN: {
    textDecoration: 'none',
    color: '#222',
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 24,
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: `${theme.palette.text.hint}40`,
  },
  errorMessage: {
    textAlign: 'center',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },

  formButtons: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  formInput: {
    width: '40%',
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400,
    width: '40%',
    cursor: 'pointer',
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
  },
}));
