import ApiRiskTool from './ApiRiskTool';

export default class AuthRiskAPIs extends ApiRiskTool {
  login(email, password) {
    return this.instance.post('authentication', { email, password, strategy: 'local' });
  }

  resetPassword(email) {
    return this.instance.post('functions/resetPassword', { email, strategy: 'local' });
  }
}
