/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// components material ui
import {
  Grid, Button, CircularProgress,
} from '@material-ui/core';
// context components
import { useBrandDispatch, dispatchAnswers, useBrandState } from '../../../../context/BrandContext';
// components local
import TabsHeader from './tabsHeader/TabsHeader';

// styles
import useStyles from './styles';

const Questionaire = ({
  questions,
  handleSize,
  handleAnswers,
  size,
  beAnswers,
  loading,
  url,
  updated,
  setUpdated,
  index,
  setIndex,
  criteria,
  riskStatus,
  brandId,
  answersWithError,
  brand,
  handleBrandStatus,
  setAnswers,
  answersLocal,
  dependencies,
  openForm,
  setOpenForm,
  expanded,
  setExpanded,
  // verificatedQuestionsUp,
}) => {
  const classes = useStyles();
  if (!questions) return <CircularProgress />;
  const brandDispatch = useBrandDispatch();
  const { verifiedAnswers, answers } = useBrandState();
  // local state
  useEffect(() => {
    const getVerified = () => {
      const { environment, labour, animal } = questions;
      const answersWithStatusVerified = answers.filter((a) => a.status === 'Verified');
      const verified = [];
      if (environment && answersWithStatusVerified.length > 0) {
        environment.map((q) => {
          if (q.answers.some((qa) => answersWithStatusVerified.filter((a) => a.id === qa.id))) {
            verified.push(q.id);
          }
          return null;
        });
      }
      if (labour && answersWithStatusVerified.length > 0) {
        labour.map((q) => {
          if (q.answers.some((qa) => answers.filter((a) => a.status === 'Verified' && a.id === qa.id))) {
            verified.push(q.id);
          }
          return null;
        });
      }
      if (animal && answersWithStatusVerified.length > 0) {
        animal.map((q) => {
          if (q.answers.some((qa) => answers.filter((a) => a.status === 'Verified' && a.id === qa.id))) {
            verified.push(q.id);
          }
          return null;
        });
      }
      return verified;
    };

    dispatchAnswers(brandDispatch, { answers: answersLocal, verifiedAnswers: getVerified() });
  }, [answersLocal]);

  const [sizeBrand, setSizeBrand] = useState(size);
  const [isCorrectURL, setIsCorrectURL] = useState(!!size);

  const [numberQuestionsAnswered, setNumbnerQuestionsAnswered] = useState({
    environment: 0, labour: 0, animal: 0,
  });

  // effects
  useEffect(() => {
    const fixedDataFromBe = beAnswers.map((item) => ({
      ...item,
    }));
    setAnswers(fixedDataFromBe || []);
  }, [beAnswers]);

  const isQuestionAnswered = (questionId, sectionQuestions) => {
    if (sectionQuestions === undefined || sectionQuestions.length === 0) return false;
    const questionAnswered = sectionQuestions.find((q) => q.id === questionId);
    let hasBeenAnswered = false;
    if (questionAnswered && questionAnswered.answers.length > 0) {
      beAnswers.map((a) => questionAnswered.answers.map((item) => {
        if (a.id === item.id || a.answer === item.id) {
          hasBeenAnswered = true;
          return hasBeenAnswered;
        }
        return null;
      }));
    }
    return hasBeenAnswered;
  };

  const countAnswers = (sectionQeustions, section) => {
    if (sectionQeustions && sectionQeustions.length > 0) {
      sectionQeustions.map((q) => {
        if (isQuestionAnswered(q.id, sectionQeustions)) {
          setNumbnerQuestionsAnswered({
            ...numberQuestionsAnswered,
            [section]: numberQuestionsAnswered[section] += 1,
          });
          return numberQuestionsAnswered;
        }
        return null;
      });
    }
    return null;
  };

  const countAllTheAnswers = () => {
    countAnswers(questions.environment, 'environment');
    countAnswers(questions.labour, 'labour');
    countAnswers(questions.animal, 'animal');
    return numberQuestionsAnswered;
  };

  //  handlers

  const nextTab = (i) => {
    if (index < 5)setIndex(i + 1);
  };

  const switchStepsTab = (i) => {
    //* most of this logic will change when we have status from BE

    switch (i) {
      // Size
      case 0:
        return {
          goAhead: () => handleSize(sizeBrand, () => nextTab(i)),
          completed: () => (sizeBrand !== null) && isCorrectURL,
        };
        // Environment;
      case 1:
        return {
          goAhead: () => handleSize(sizeBrand, () => nextTab(i)),
          completed: () => countAnswers(questions.environment, 'environment'),
        };
      // Labour
      case 2:
        return {
          goAhead: () => handleSize(sizeBrand, () => nextTab(i)),
          completed: () => countAnswers(questions.labour, 'labour'),
        };
      // Animals
      case 3:
        return {
          goAhead: () => handleSize(sizeBrand, () => nextTab(i)),
          completed: () => countAnswers(questions.animal, 'animal'),
        };
      // Review
      case 4:
        return {
          goAhead: () => handleSize(sizeBrand, () => nextTab(i)),
        };
        // Completed
      case 5:
        return {
          goAhead: () => handleSize(sizeBrand, () => nextTab(i)),
        };
      default:
        return () => nextTab(i);
    }
  };

  const isDisabled = !isCorrectURL || sizeBrand === null;
  const disableSaveAnswers = riskStatus[0] && riskStatus[0].status === 'In verification';
  const isReadyToBeReviewed = ['Not started', 'In progress'].includes(riskStatus) || !riskStatus;

  const handleVerificationQuestions = (e, qId, qAnswersId) => {
    const answersOfQuestionSelected = answers
      .filter((a) => qAnswersId.includes(a.id))
      .map((a) => a.id);
    if (!e.target.checked) { // if unchecked
      const markedAnswersAsNotVerified = answers
        .map((a) => (answersOfQuestionSelected.includes(a.id) ? { ...a, isNew: false, status: 'Active' } : a));
      dispatchAnswers(brandDispatch,
        {
          verifiedAnswers: [...verifiedAnswers.filter((item) => item !== qId)],
          answers: markedAnswersAsNotVerified,
        });
    } else {
      const markedAnswersAsVerified = answers
        .map((a) => (answersOfQuestionSelected.includes(a.id) ? { ...a, isNew: true, status: 'Verified' } : a));
      // setAnswers(markedAnswersAsVerified);
      dispatchAnswers(brandDispatch,
        {
          verifiedAnswers: [...verifiedAnswers, qId],
          answers: markedAnswersAsVerified,
        });
    }
  };

  const handleOutDatedAnswer = (e, q) => {
    const questionsAnswersId = q.answers.map((a) => a.id);
    const answersOfTheQuestionSelected = answers.filter((a) => questionsAnswersId.includes(a.id));
    let newsAnswers = [];
    if (e.target.checked) { // if unchecked{
      newsAnswers = answers.map((a) => {
        if (answersOfTheQuestionSelected.includes(a)) {
          return { ...a, isNew: true };
        }
        return a;
      });
    } else {
      newsAnswers = answers.map((a) => {
        if (answersOfTheQuestionSelected.includes(a)) {
          return { ...a, isNew: false };
        }
        return a;
      });
    }
    setAnswers(newsAnswers);
  };
  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} alignItems="center" direction="column" wrap="nowrap">
        <TabsHeader
          questions={questions}
          dependencies={dependencies}
          changeValue={setIndex}
          value={index}
          size={size}
          setSize={setSizeBrand}
          answers={answersLocal}
          setAnswers={setAnswers}
          beAnswers={beAnswers}
          isQuestionAnswered={isQuestionAnswered}
          url={url}
          isCorrectURL={isCorrectURL}
          setIsCorrectURL={setIsCorrectURL}
          updated={updated}
          setUpdated={setUpdated}
          areAllQuestionsAnswered={countAllTheAnswers}
          criteria={criteria}
          riskStatus={riskStatus}
          brandId={brandId}
          answersWithError={answersWithError}
          brandName={brand.name}
          handleBrandStatus={handleBrandStatus}
          handleVerificationQuestions={handleVerificationQuestions}
          verificatedQuestions={verifiedAnswers}
          openForm={openForm}
          setOpenForm={setOpenForm}
          handleAnswers={handleAnswers}
          showSaveButton={index < 4 && index > 0}
          expanded={expanded}
          setExpanded={setExpanded}
          handleOutDatedAnswer={handleOutDatedAnswer}
        />
        <Grid container item xs={12} justifyContent="flex-end" style={{ maxHeight: 100 }}>

          {/* Size save btn */}
          {index === 0 && (
            <>
              <Grid item style={{ marginLeft: '1rem' }}>
                <Button
                  variant={loading ? 'outlined' : 'contained'}
                  onClick={switchStepsTab(index).goAhead}
                  color="primary"
                  disabled={isDisabled}
                >
                  {loading ? <CircularProgress size={16} color="secondary" /> : 'Save' }
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: '1rem' }}>
                <Button
                  variant="contained"
                  onClick={() => nextTab(index)}
                  color="primary"
                  disabled={isDisabled || criteria.length === 0}
                >
                  {loading ? <CircularProgress size={16} color="secondary" /> : 'Next' }
                </Button>
              </Grid>
            </>
          )}

          {/* Questionnaire answers save btn */}
          {index > 0 && index < 4 && (
            <>
              <Grid item style={{ marginLeft: '1rem', paddingRight: 24, alignSelf: 'end' }}>
                <Button
                  variant={loading ? 'outlined' : 'contained'}
                  onClick={() => handleAnswers(answersLocal, false)}
                  disabled={disableSaveAnswers}
                  color="primary"
                >
                  {loading ? <CircularProgress size={16} color="secondary" /> : 'Save answers' }
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: '1rem', paddingRight: 24, alignSelf: 'end' }}>
                <Button
                  variant="contained"
                  onClick={() => nextTab(index)}
                  color="primary"
                >
                  {loading ? <CircularProgress size={16} color="secondary" /> : 'Next' }
                </Button>
              </Grid>
            </>
          )}

          {/* Review btn */}
          {index === 8 && (
          <Grid item style={{ marginLeft: '1rem' }}>
            <Button
              variant={loading ? 'outlined' : 'contained'}
              disabled={isReadyToBeReviewed}
              onClick={() => handleAnswers(answersLocal, true)}
              color="primary"
            >
              {loading ? <CircularProgress size={16} color="secondary" /> : 'Save Review' }
            </Button>
          </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
Questionaire.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  handleSize: PropTypes.func.isRequired,
  handleAnswers: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  questions: PropTypes.shape({
    environment: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    animal: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    labour: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  url: PropTypes.string.isRequired,
  updated: PropTypes.bool.isRequired,
  setUpdated: PropTypes.func.isRequired,
  setIndex: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  criteria: PropTypes.arrayOf(PropTypes.string).isRequired,
  riskStatus: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  brand: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleBrandStatus: PropTypes.func.isRequired,
  answersLocal: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAnswers: PropTypes.func.isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  // verificatedQuestionsUp: PropTypes.func.isRequired,
};

export default Questionaire;
