/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// components material ui
import {
  Grid, Typography,
} from '@material-ui/core';
import Loader from '../../../../../components/common/loader/Loader';

// components local
import AccordionContentReview from '../AccordionContentReview';
// styles
import useStyles from './styles';

function TabContentReview({
  questions, answers, setAnswers, beAnswers, isQuestionAnswered, setUpdated, updated,
  value, changeValue, answersWithError, verificatedQuestions, isVerified,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (questions) {
      setUpdated(!updated);
    }
    return null;
  }, []);

  const slugDependenciesQuestion = answers.map((answer) => answer.slug);
  const slugDependenciesAnswer = answers.map((answer) => answer.slug);
  const answersId = answers.map((answer) => answer.id);
  //  handlers
  if (!questions || !beAnswers) return <Loader />;

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
    >
      <Typography variant="h6" className={classes.title}>
        Environment
      </Typography>
      <AccordionContentReview
        questions={questions.environment}
        answers={answers}
        setAnswers={setAnswers}
        beAnswers={beAnswers}
        isQuestionAnswered={isQuestionAnswered}
        slugDependenciesQuestion={slugDependenciesQuestion}
        slugDependenciesAnswer={slugDependenciesAnswer}
        changeValue={changeValue}
        answersWithError={answersWithError}
        value={1}
        verificatedQuestions={verificatedQuestions}
      />
      <Typography variant="h6" className={classes.title}>
        Labour
      </Typography>
      <AccordionContentReview
        questions={questions.labour}
        changeValue={changeValue}
        answers={answers}
        setAnswers={setAnswers}
        beAnswers={beAnswers}
        isQuestionAnswered={isQuestionAnswered}
        slugDependenciesQuestion={slugDependenciesQuestion}
        slugDependenciesAnswer={slugDependenciesAnswer}
        answersWithError={answersWithError}
        value={2}
        verificatedQuestions={verificatedQuestions}
      />
      <Typography variant="h6" className={classes.title}>
        Animal
      </Typography>
      <AccordionContentReview
        questions={questions.animal}
        changeValue={changeValue}
        answers={answers}
        setAnswers={setAnswers}
        beAnswers={beAnswers}
        isQuestionAnswered={isQuestionAnswered}
        slugDependenciesQuestion={slugDependenciesQuestion}
        slugDependenciesAnswer={slugDependenciesAnswer}
        answersWithError={answersWithError}
        value={3}
        verificatedQuestions={verificatedQuestions}
        isVerified={isVerified}
      />
    </Grid>
  );
}

TabContentReview.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
  questions: PropTypes.PropTypes.shape({
    environment: PropTypes.arrayOf(PropTypes.shape({})),
    labour: PropTypes.arrayOf(PropTypes.shape({})),
    animal: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  beAnswers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isQuestionAnswered: PropTypes.func.isRequired,
  setUpdated: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  changeValue: PropTypes.func.isRequired,
  answersWithError: PropTypes.arrayOf(PropTypes.string).isRequired,
  verificatedQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVerified: PropTypes.func.isRequired,
};
export default TabContentReview;
