import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: '#222',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    fontSize: '.87rem',
  },
}));
