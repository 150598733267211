// eslint-disable-next-line import/prefer-default-export
export const generalQuestions = {
  id: '130',
  slug: 'general',
  headline: 'general',
  theme: 'general',
  question: "Does it clearly meet at least one of the following 'large brand' criteria?",
  indicator: 'general',
  answers: [
    {
      id: 'general-0',
      slug: '',
      text: 'Is listed on the stock exchange',
      criteria: 'stock',
    },
    {
      id: 'general-1',
      slug: '',
      text: 'Is a Subsidiary company',
      criteria: 'subsidiary',
    },
    {
      id: 'general-2',
      slug: '',
      text: 'Is a Parent company',
      criteria: 'parent',
    },
    {
      id: 'general-3',
      slug: '',
      text: 'Has a turnover >= 50M Euro',
      criteria: 'turnover',
    },
    {
      id: 'general-4',
      slug: '',
      text: 'Has Alexa rating <200k',
      criteria: 'alexa',
    },
    {
      id: 'general-5',
      slug: '',
      text: 'Has Insta + Facebook followers >=500k',
      criteria: 'insta-fb',
    },
    {
      id: 'general-6',
      slug: '',
      text: 'Has Linkedin employees >= 250',
      criteria: 'linked-in',
    },
    {
      id: 'general-7',
      slug: '',
      text: 'Is considered large according to the other Good On You criteria',
      criteria: 'goy-large',
    },
    {
      id: 'general-8',
      slug: '',
      text: 'None of the above, therefore is considered small',
      criteria: 'none',
    },
    {
      id: 'general-9',
      slug: '',
      text: 'Good On You is satisfied that the company is small, despite meeting some of the above criteria',
      criteria: 'goy-small',
    },
  ],
};
