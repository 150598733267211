import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    minHeight: '60vh',
  },
  textContainer: {
    padding: '.75rem 0',
  },
  row: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  tabPanel: {
    '&:first-child': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:last-child': {
      '& p': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
}));
