import React from 'react';
import ReactDOM from 'react-dom';
import Slide from '@material-ui/core/Slide';
import { SnackbarProvider } from 'notistack'; import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import Themes from './themes';
import './assets/fonts/fonts.css';
import App from './components/App';
import { UserProvider } from './context/UserContext';
import { BrandProvider } from './context/BrandContext';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <BrandProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <SnackbarProvider
            preventDuplicate
            hideIconVariant={false}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
              autoHideDuration: 3000,
            }}
            TransitionComponent={Slide}
          >
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </BrandProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
